import React, { useState, useEffect, useRef, useCallback } from 'react';
import 'animate.css'; // Import Animate.css
import backgroundImage from 'assests/logos/background.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import getRoomInventory from 'routes/api/getRoomInventory';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import DataView from '../components/DataView';
import { Slide } from 'react-slideshow-image';
import img from '../../../assests/logos/openBackground.png';
import 'react-slideshow-image/dist/styles.css';
import TimePicker from '../components/timePicker';

/**
 * HotelsPage Component
 * Renders the hotels page with a similar style to the hero cover section.
 * @returns {JSX.Element} - The HotelsPage component.
 *
 *
 *
 */

const HotelSearch = () => {
  const ConfirmOrdersButtonRef = useRef(null);
  const [displayPrice, setDisplayPrice] = useState(0);
  const [showAdditionalButtons, setShowAdditionalButtons] = useState(false);
  const [isHourlyClicked, setIsHourlyClicked] = useState(false);
  const [isDailyClicked, setIsDailyClicked] = useState(false);
  const [activeRoomTypeID, setActiveRoomTypeID] = useState(null);
  const [animationClass, setAnimationClass] = useState('');
  const [showMoreButtons, setShowMoreButtons] = useState(false);
  const [showDailyButtons, setShowDailyButtons] = useState(false);
  const [showMoreSuperior, setShowMoreSuperior] = useState(false);
  const [showMoreDeluxe, setShowMoreDeluxe] = useState(false);
  const [roomdailyDisplayPrice, setRoomdailyDisplayPrice] = useState(0);
  const [roomHourlyDisplayPrice, setRoomHourlyDisplayPrice] = useState(0);
  const [indexActive, setIndexActive] = useState(null);

  const [showMoreButtonsC, setShowMoreButtonsC] = useState(false);
  const [moreAnimationClass, setMoreAnimationClass] = useState('');
  const [todayDate, setTodayDate] = useState('');
  const [todayTime, setTodayTime] = useState('');
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [roomList, setRoomList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [hourlyRoomList, setHourlyRoomList] = useState([]);
  const [dailyRoomList, setDailyRoomList] = useState([]);
  const [modalDetail, setModalDetail] = useState(false);
  const [dynamicPrice1, setDynamicPrice1] = useState('');

  const [showRoomPrice, setShowRoomPrice] = useState(false);
  const [description, setDescription] = useState('');

  const [rate, setRate] = useState([]);
  const [showRoomType, setShowRoomType] = useState(false);
  const [timeModalView, setTimeModalView] = useState(false);

  const [imageList, setImageList] = useState([]);
  const [hourlyRate, setRateHourly] = useState([]);
  const [status, setStatus] = useState(0);
  const statusRef = useRef(status);
  const nav = useNavigate();

  const handleBackClick = () => {
    nav('/');
  };
  const fetchData = async () => {
    try {
      const dateReservation = new Date(
        localStorage.getItem('date-reservation')
      );

      const icNo = localStorage.getItem('ICNO');
      const auth = localStorage.getItem('token');
      const url = localStorage.getItem('api_URL');
      const room = JSON.parse(localStorage.getItem('room'));
      const desc = localStorage.getItem('desc');
      const imagesList = JSON.parse(localStorage.getItem('imagesList'));
      const response = await getRoomInventory(icNo, auth, dateReservation, url);

      const data = response[0]?.Data || [];

      const date = new Date();

      localStorage.setItem(
        'date-reservation',
        new Intl.DateTimeFormat('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        }).format(dateReservation)
      );

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');

      const formattedDateTime = `${year}-${month}-${day}T${hours}:00:00`;
      const formattedDate = `${year}-${month}-${day}`;

      console.log(formattedDateTime);
      const availableRoomsHourly = data.filter((room) =>
        room.StockAvailability.some(
          (availability) =>
            availability.OnDateTime === formattedDateTime &&
            availability.Stock > 0
        )
      );

      console.log(availableRoomsHourly);

      const availabelRoomsDaily = data.filter((room) =>
        room.StockAvailabilityDaily.some(
          (availability) =>
            availability.OnDate == formattedDate && availability.Available > 0
        )
      );

      setDailyRoomList(availabelRoomsDaily);
      setHourlyRoomList(availableRoomsHourly);
      const index = data.findIndex((rooms) => rooms.RoomTypeID == room.roomID);

      if (localStorage.getItem('history') == 'hourly') {
        setIsHourlyClicked(true);
        setShowAdditionalButtons(true);
        setRoomList(availableRoomsHourly);
        setShowRoomType(true);
        setActiveRoomTypeID(room.roomID);
        setShowRoomPrice(true);
        setImageList(imagesList);
        setDescription(desc);

        setRate(data[index].RateDetails);
        console.log(data[index]);
        setRateHourly(data[index].RateDetailsHourly);
        if (localStorage.getItem('price')) {
          setStatus(2);
          console.log(
            data[index].RateDetailsHourly.findIndex(
              (rate) => rate.tariff == localStorage.getItem('price')
            ) + 1
          );
          setIndexActive(
            data[index].RateDetailsHourly.findIndex(
              (rate) => rate.tariff == localStorage.getItem('price')
            ) + 1
          );
        }
      } else if (localStorage.getItem('history') == 'daily') {
        setIsDailyClicked(true);
        setShowDailyButtons(true);
        setShowRoomType(true);
        setActiveRoomTypeID(room.roomID);
        setShowRoomPrice(true);
        setRoomList(availabelRoomsDaily);
        setImageList(imagesList);
        setDescription(desc);
        setRate(data[index].RateDetails);
        setRateHourly(data[index].RateDetailsHourly);
        if (localStorage.getItem('price')) {
          setStatus(2);

          setIndexActive(
            data[index].RateDetails.findIndex(
              (rate) => rate.Tariff == localStorage.getItem('price')
            ) + 1
          );
        }
      }
    } catch (error) {
      console.error('Error fetching room inventory:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    statusRef.current = status;
  }, [status]);

  useEffect(() => {
    if (localStorage.getItem('history') === 'daily') {
      setRoomList(dailyRoomList);
    } else if (localStorage.getItem('history') === 'hourly') {
      setRoomList(hourlyRoomList);
    }
  }, [localStorage.getItem('history')]);

  const handleConfirmOrdersClick = () => {
    localStorage.setItem('desc', description);

    const button = ConfirmOrdersButtonRef.current;
    if (button) {
      console.log('Is Button Disabled:', button.disabled);

      if (status == 2) {
        button.style.animation = 'zoomIn 1s forwards';
        setTimeout(() => {
          nav('/ConfirmOrders');
        }, 1000);
      }
    }
  };

  const HandleDatePicker = () => {
    setShowDatePicker((prevShowDatePicker) => !prevShowDatePicker);
  };

  const HandleTimePicker = () => {
    setTimeModalView(true);
  };

  const handleHourlyClick = () => {
    localStorage.setItem('history', 'hourly');
    setIndexActive(null);
    setStatus(1);
    if (isHourlyClicked) {
      setAnimationClass('animate__fadeOut');

      setTimeout(() => {
        setShowAdditionalButtons(false);
        setShowRoomType(false);
        setIsHourlyClicked(false);
        setShowMoreButtons(false);
        setShowMoreButtonsC(false);
        setShowMoreSuperior(false);
        setShowMoreDeluxe(false);
        setAnimationClass('animate__fadeIn');
      }, 500); // Match the animation duration
    } else {
      setShowAdditionalButtons(true);
      setShowRoomType(true);
      setIsHourlyClicked(true);
      setShowDailyButtons(false);
      setIsDailyClicked(false);
      setShowMoreSuperior(false);
      setShowMoreDeluxe(false);
      setAnimationClass('animate__fadeIn');
    }
  };

  const handleDailyClick = () => {
    setIndexActive(null);
    setStatus(1);
    localStorage.setItem('history', 'daily');
    if (isDailyClicked) {
      setAnimationClass('animate__fadeOut');

      setTimeout(() => {
        setShowDailyButtons(false);
        setShowRoomType(false);
        setIsDailyClicked(false);
        setShowMoreButtons(false);
        setShowMoreButtonsC(false);
        setShowMoreSuperior(false);
        setShowMoreDeluxe(false);
        setAnimationClass('animate__fadeIn');
      }, 500); // Match the animation duration
    } else {
      setShowDailyButtons(true);
      if (selectedDate == null) {
        setShowRoomType(true);
      } else {
        setShowRoomType(true);
      }
      setIsDailyClicked(true);
      setShowAdditionalButtons(false);
      setShowRoomType(true);
      setIsHourlyClicked(false);
      setShowMoreButtons(false);
      setShowMoreButtonsC(false);
      setShowMoreSuperior(false);
      setShowMoreDeluxe(false);
      setAnimationClass('animate__fadeIn');
    }
  };

  useEffect(() => {
    const dateInput = document.getElementById('dateInput');
    const timeInput = document.getElementById('timeInput');
    if (dateInput && timeInput) {
      dateInput.value = todayDate;
      timeInput.value = todayTime;
    }
  }, [todayDate, todayTime]);

  const hanldeModalBack = () => {
    setModalDetail(false);
  };

  const handleDateSelect = async (date) => {
    const tanggal = new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    }).format(date);

    localStorage.setItem('date-reservation', tanggal);

    const IcNo = localStorage.getItem('ICNO');
    const authorization = localStorage.getItem('token');
    const apiURL = localStorage.getItem('api_URL');

    const response = await getRoomInventory(
      IcNo,
      authorization,
      tanggal,
      apiURL
    );

    if (response) {
      console.log(response[0]);
    }

    setRoomList(response[0].Data);

    setShowDatePicker(false);
    setSelectedDate(
      new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      }).format(date)
    );

    setShowRoomType(true);
  };

  const handlePictureClick = () => {
    setModalDetail(true);
  };

  useEffect(() => {
    if (localStorage.getItem('history') == 'daily') {
      setDisplayPrice(roomdailyDisplayPrice);
    } else if (localStorage.getItem('history') == 'hourly') {
      setDisplayPrice(roomHourlyDisplayPrice);
    }
  }, [
    roomdailyDisplayPrice,
    roomHourlyDisplayPrice,
    localStorage.getItem('history'),
  ]);

  useEffect(() => {
    console.log(hourlyRate);
  }, [hourlyRate]);

  const handleRoomTypeDailyClick = (
    roomTypeID,
    roomAdult,
    roomRate,
    roomRateHourly,
    roomDailyPrice,
    roomHourlyPrice,
    roomType
  ) => {
    setStatus(1);

    setActiveRoomTypeID(roomTypeID);

    setRateHourly(roomRateHourly);
    setRate(roomRate);
    setRoomHourlyDisplayPrice(roomHourlyPrice);
    setRoomdailyDisplayPrice(roomDailyPrice);

    setIndexActive(null);
    localStorage.setItem('price', '');
    localStorage.setItem('guest', roomAdult);
    localStorage.setItem('room_name', roomType);

    const selectedRoom = roomList.find(
      (room) => room.RoomTypeID === roomTypeID
    );

    handleClickedDailyRoomType(selectedRoom);
  };

  useEffect(() => {
    if (rate) {
      localStorage.setItem('rate', JSON.stringify(rate));
      console.log(rate);
    }
  }, [rate]);

  const handleClickedDailyRoomType = async (room) => {
    console.log(room);
    handleRoomType();
    setDescription(room.RoomTypeDescription);
    localStorage.setItem('desc', room.RoomTypeDescription);

    // Prepare and update image list
    let imageContainer = room.Images.map((element) => element.Image);
    setImageList(imageContainer);

    localStorage.setItem(
      'room',
      JSON.stringify({ roomID: room.RoomTypeID, roomTypeName: room.RoomType })
    );
    localStorage.setItem('imagesList', JSON.stringify(imageContainer));
  };

  const handleRoomType = () => {
    // setShowMoreSuperior(prevShowMoreSuperior => !prevShowMoreSuperior);
    if (showRoomPrice) {
      // setMoreAnimationClass('animate__fadeOut');
      setTimeout(() => {
        setShowRoomPrice(true);
        setMoreAnimationClass('animate__fadeIn');
      }, 500); // Match the animation duration
    } else {
      setShowRoomPrice(true);
    }
  };
  useEffect(() => {
    console.log('Updated price:', dynamicPrice1);
  }, [dynamicPrice1]);

  const handlePrice1Click = useCallback(
    //rateId == session ID
    (tarif, rateId, durationStay, rId, tarifID) => {
      setDynamicPrice1(tarif);
      localStorage.setItem('durationStay', durationStay);

      if (localStorage.getItem('history') == 'hourly')
        localStorage.setItem(
          'hourlyRate',
          JSON.stringify({
            hourlysession_id: rateId,
            hourlyrate_id: rId,
            hourly_tarifID: tarifID,
          })
        );

      setIndexActive((prev) => {
        let newShowMorePrice1;

        if (prev === rateId) {
          newShowMorePrice1 = null;
          setDynamicPrice1(null);
          localStorage.removeItem('price');
        } else {
          newShowMorePrice1 = rateId;
          localStorage.setItem('price', tarif);
        }

        let newStatus;
        if (localStorage.getItem('price')) {
          newStatus = 2;
        } else {
          newStatus = 1;
        }

        setStatus(newStatus);
        statusRef.current = newStatus;

        return newShowMorePrice1;
      });
    },
    [status]
  );

  return (
    <div
      className="bg-brand min-h-screen text-slate-1000 flex w-screen flex-col overflow-hidden"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer',
        backgroundPositionX: '900px',
        animation: 'fadeInBackground 1s ease-in-out',
        overflowX: 'hidden',
      }}
    >
      <style>
        {`
          @keyframes fadeInBackground {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
          .modal {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
          }
          .modal-content {
            background: white;
            padding: 20px;
            border-radius: 8px;
            position: relative;
          }
          .close-button {
            position: absolute;
            top: 10px;
            right: 10px;
            background: transparent;
            border: none;
            font-size: 20px;
            cursor: pointer;
          }

          ::-webkit-scrollbar{
            display : none
          }
        `}
      </style>
      <div className="imageList flex flex-row flex-wrap container ml-4 px-2 md:px-0 mt-1 space justify-between w-full h-[596.67px]">
        <div className="hero-content__text py-4 flex flex-col">
          <h1 className="text-[#004AAD] font-bold text-[2rem] w-[347.91px]">
            BOOKING
          </h1>
          <div className="flex flex-row gap-4">
            <h3 className="text-4xl font-medium w-2/5">
              <button
                className={`px-9 rounded-full font-montserrat font-bold text-[45%] ${
                  isHourlyClicked
                    ? 'bg-[#C6D9F1] text-white border-2 border-[#004AAD]'
                    : 'bg-[#D9D9D9] text-[#004AAD] border-2 border-transparent'
                }`}
                onClick={handleHourlyClick}
              >
                HOURLY
              </button>
            </h3>
            <h3 className="text-4xl font-medium w-2/5">
              <button
                className={`px-10 rounded-full font-montserrat font-bold text-[45%] w-full ${
                  isDailyClicked
                    ? 'bg-[#C6D9F1] text-white border-2 border-[#004AAD]'
                    : 'bg-[#D9D9D9] text-[#004AAD] border-2 border-transparent'
                }`}
                onClick={handleDailyClick}
              >
                DAILY
              </button>
            </h3>
          </div>

          <div className="grid grid-cols-1 gap-4 mt-2">
            <div className={`animate__animated ${moreAnimationClass}`}>
              {showAdditionalButtons && (
                <>
                  <h3 className="text-4xl font-medium">
                    <button className="px-5 rounded-full font-montserrat font-bold text-[45%] text-[#004AAD] bg-[#D9D9D9] w-[40%]">
                      DATE
                    </button>
                    <input
                      type="text"
                      className="px-5 rounded-full font-montserrat bg-[#D9D9D9] text-center text-[#004AAD] font-bold text-[40%] ml-2.5 cursor-pointer w-[42%]"
                      onClick={HandleDatePicker}
                      value={
                        localStorage.getItem('date-reservation') ||
                        'SELECT DATE'
                      }
                      readOnly
                    />
                  </h3>

                  <h3 className="text-4xl font-medium mt-2">
                    <button className="px-5 rounded-full font-montserrat font-bold text-[45%] w-[40%] bg-[#D9D9D9] text-[#004AAD] ml-0">
                      TIME
                    </button>
                    <input
                      type="text"
                      value={`${JSON.parse(localStorage.getItem('time-reservation')).time} : ${JSON.parse(localStorage.getItem('time-reservation')).minute}`}
                      className="px-5 rounded-full text-center font-montserrat font-bold text-[45%] ml-2.5 text-[#004AAD] bg-[#D9D9D9] w-[42%] cursor-pointer"
                      readOnly
                      onClick={HandleTimePicker}
                    />
                  </h3>
                </>
              )}
              {showDailyButtons && (
                <>
                  <h3 className="text-4xl font-medium flex gap-1">
                    <button className="px-5 rounded-full font-montserrat font-bold text-[45%] w-[40%] bg-[#D9D9D9] text-[#004AAD]">
                      DATE
                    </button>
                    <input
                      type="text"
                      className="px-5  rounded-full font-montserrat bg-[#D9D9D9] text-[#004AAD] font-bold text-[40%] text-center ml-2 cursor-pointer w-[40%]"
                      value={
                        localStorage.getItem('date-reservation') ||
                        'SELECT DATE'
                      }
                      onClick={HandleDatePicker}
                      readOnly
                    />
                  </h3>
                </>
              )}
            </div>
            <div className={`animate__animated ${moreAnimationClass} w-3/4`}>
              {showRoomType ? (
                <>
                  <h3 className="text-4xl font-medium">
                    <button
                      className="rounded-full font-montserrat bg-[#004AAD] text-white w-[115%] font-bold text-[45%]"
                      disabled
                    >
                      ROOM TYPE
                    </button>
                  </h3>
                  {roomList && roomList.length > 0 ? (
                    roomList.map((room) => (
                      <h3
                        className="text-4xl font-medium mt-1"
                        key={room.RoomTypeID}
                      >
                        <button
                          className={`px-5 rounded-full font-montserrat font-bold border-2 ${
                            activeRoomTypeID === room.RoomTypeID
                              ? 'text-white bg-blue-100 border-[#004AAD]'
                              : 'text-[#004AAD] bg-gray-200 border-transparent'
                          }`}
                          style={{ width: '115%', fontSize: '45%' }}
                          onClick={() =>
                            handleRoomTypeDailyClick(
                              room.RoomTypeID,
                              room.Adult,
                              room.RateDetails,
                              room.RateDetailsHourly,
                              room.RateDetails[0].Tariff,
                              room.RateDetailsHourly[0].tariff,
                              room.RoomType
                            )
                          }
                        >
                          {room.RoomType.toUpperCase()}
                        </button>
                      </h3>
                    ))
                  ) : (
                    <div className="text-center font-bold py-4 w-[115%] text-[#004AAD]">
                      <p>The Room is Currently Not Available </p>
                      <p>For Today Please Check In Next Time</p>
                    </div>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>

        {showRoomPrice && isDailyClicked && dailyRoomList.length > 0 && (
          <div className="imageList flex flex-col container  md:px-0 h-auto w-1/5 m-0 mt-16">
            <h3 className="text-4xl font-medium">
              <button
                className="rounded-full font-montserrat bg-[#004AAD] border-[#004AAD] text-[white] w-full font-bold text-[45%]"
                disabled
              >
                ROOM RATE
              </button>
            </h3>
            {rate.map((rateItem, i) => (
              <h3 className="text-4xl font-medium mt-2 w-full" key={i}>
                <button
                  className={`rounded-full font-montserrat ${
                    indexActive == rateItem.rateid
                      ? 'bg-[#C6D9F1] text-white border-2 border-[#004AAD]'
                      : 'bg-[#D9D9D9] text-[#004AAD]'
                  } w-full font-bold text-[45%]`}
                  onClick={() =>
                    handlePrice1Click(
                      rateItem.Tariff,
                      rateItem.rateid,
                      '1 Night'
                    )
                  }
                >
                  <span>{rateItem.ratePlan}</span>
                  <span className="ml-[50px]" id="t1">
                    {`${new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(rateItem.Tariff)}`}
                  </span>
                </button>
              </h3>
            ))}
          </div>
        )}

        {showDatePicker && (
          <div className="modal">
            <div className="modal-content">
              <DatePicker
                minDate={new Date()}
                selected={selectedDate}
                value={selectedDate}
                onChange={handleDateSelect}
                onClickOutside={() => setShowDatePicker(false)}
                inline
              />
            </div>
          </div>
        )}
        {showRoomPrice && isHourlyClicked && hourlyRoomList.length > 0 && (
          <div className="imageList flex flex-col container  md:px-0 h-auto w-1/5 m-0 mt-16">
            <h3 className="text-4xl font-medium">
              <button
                className="rounded-full font-montserrat bg-[#004AAD] border-[#004AAD] text-[white] w-full font-bold text-[45%]"
                disabled
              >
                ROOM RATE
              </button>
            </h3>
            {hourlyRate.map((rateItem, i) => (
              <h3 className="text-4xl font-medium mt-2 w-full" key={i}>
                <button
                  className={`rounded-full font-montserrat ${
                    indexActive == rateItem.sessionID
                      ? 'bg-[#C6D9F1] text-white border-2 border-[#004AAD]'
                      : 'bg-[#D9D9D9] text-[#004AAD]'
                  } w-full font-bold text-[45%]`}
                  onClick={() =>
                    handlePrice1Click(
                      rateItem.tariff,
                      rateItem.sessionID,
                      rateItem.HourlyName,
                      rateItem.rID,
                      rateItem.tariffID
                    )
                  }
                >
                  <span>{rateItem.HourlyName}</span>
                  <span className="ml-[50px]" id="t1">
                    {`${new Intl.NumberFormat('ja-JP', {
                      style: 'currency',
                      currency: 'IDR',
                      minimumFractionDigits: 0,
                    }).format(rateItem.tariff)}`}
                  </span>
                </button>
              </h3>
            ))}
          </div>
        )}

        {showRoomPrice &&
          (isDailyClicked || isHourlyClicked) &&
          roomList.length != 0 && (
            <div className=" flex flex-col container md:px-0 mt-8 mr-10 h-auto w-1/4 m-0 relative">
              <div className="rounded-2xl overflow-hidden h-[25vh]">
                <div className="w-[10%]  absolute top-[15%] left-[45%] z-10  rounded-xl bg-white opacity-50">
                  <img src={img} onClick={handlePictureClick} />
                </div>

                <Slide
                  duration={1000}
                  className="relative top-0 rounded-3xl h-max"
                  nextArrow={<button className="hidden"></button>}
                  prevArrow={<button className="hidden"></button>}
                >
                  {imageList.map((image) => {
                    return (
                      <div
                        className={`animate__animated ${moreAnimationClass} mb-2 z-0 h-[25vh] pt-[4%] flex items-end w-full bg-center bg-cover bg-no-repeat`}
                        style={{
                          backgroundImage: `url(${image})`,
                        }}
                      ></div>
                    );
                  })}
                </Slide>
              </div>

              <div className="border-2 border-[#004AAD] bg-whitesmoke opacity-80 mt-[5%] rounded-md px-3 overflow-scroll max-h-[40%]">
                <p className="font-bold">Room Description</p>
                {description.split('\n').map((text, i) => {
                  return (
                    <p key={i} className="mb-[2%] text-[16px]">
                      {text}
                    </p>
                  );
                })}
              </div>
            </div>
          )}
      </div>
      <div className="flex justify-between ml-4 w-[840px]">
        <button
          className="py-2.5 px-12 rounded-full font-montserrat font-bold text-[90%] bg-red-500 text-white w-max"
          onClick={handleBackClick}
        >
          BACK
        </button>

        <button
          ref={ConfirmOrdersButtonRef}
          className={`py-2.5 px-12 rounded-full font-montserrat w-max  text-[#fff] font-bold text-[90%] ${status >= 2 ? 'bg-[#00B050]' : 'bg-[#C7C8CC]'} transition-all duration-1000`}
          onClick={handleConfirmOrdersClick}
        >
          NEXT
        </button>
      </div>
      <Modal
        isOpen={modalDetail}
        onRequestClose={hanldeModalBack}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          content: {
            margin: 'auto',
            border: 'none',
            padding: '0',
            borderRadius: '10px',
            overflowY: 'hidden',
          },
        }}
      >
        <DataView
          price={displayPrice}
          room={JSON.parse(localStorage.getItem('room')).roomTypeName}
          imageList={imageList}
          description={description}
          handleClose={hanldeModalBack}
          status={localStorage.getItem('history')}
        />
      </Modal>
      <Modal
        isOpen={timeModalView}
        onRequestClose={() => {
          setTimeModalView(false);
        }}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          content: {
            margin: 'auto',
            border: 'none',
            padding: '0',
            borderRadius: '10px',
            overflowY: 'hidden',
            width: '50%',
            height: 'max-content',
            padding: '2%',
          },
        }}
      >
        <TimePicker />
      </Modal>
    </div>
  );
};

export default HotelSearch;
