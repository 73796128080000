import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useRef } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

const DataView = (props) => {
  const [index, setIndex] = useState(0);
  const slideRef = useRef(null);

  const handleChange = (newIndex) => {
    setIndex(newIndex);
    if (slideRef.current) {
      setTimeout(() => {
        slideRef.current.goTo(newIndex);
      }, 500);
    }
  };

  return (
    <div className="h-full flex overflow-y-hidden z-20">
      <div className="w-2/3 bg-slate-800 h-full">
        <h1 className="font-bold py-3 px-5 text-white text-xl">{props.room}</h1>
        <Slide
          autoplay={false}
          ref={slideRef}
          onChange={(from, to) => setIndex(to)}
          nextArrow={
            <FontAwesomeIcon
              icon={faArrowRight}
              className="bg-white p-3 mr-2 cursor-pointer rounded-3xl shadow-xl transition-all duration-300 ease-in-out hover:bg-blue-700 hover:text-white"
            />
          }
          prevArrow={
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="bg-white p-3 cursor-pointer ml-2 rounded-3xl shadow-xl transition-all duration-300 ease-in-out hover:bg-blue-700 hover:text-white"
            />
          }
        >
          {props.imageList.map((image, i) => (
            <div
              key={i}
              style={{ backgroundImage: `url(${image})` }}
              className="h-[60vh] bg-cover bg-center mx-5 rounded-2xl flex justify-between items-center p-4 text-blue-700"
            ></div>
          ))}
        </Slide>

        <div className="grid grid-cols-5 gap-4 m-5">
          {props.imageList.map((image, i) => (
            <img
              src={image}
              alt={`image-${i}`}
              key={i}
              onClick={() => handleChange(i)}
              className={`rounded-2xl cursor-pointer ${i === index ? 'opacity-100' : 'opacity-50'} h-full`}
            />
          ))}
        </div>
      </div>
      <div className="w-1/3 flex flex-col items-center">
        <h1 className="font-bold py-3 px-5 text-xl w-full">Room Detail</h1>
        <div className="m-5 mt-0 h-2/3 overflow-y-scroll">
          {props.description.split('\n').map((text, i) => (
            <p className="my-2" key={i}>
              {text}
            </p>
          ))}
        </div>
        <div className="flex justify-between w-full">
          <p className="mx-5 my-2 font-bold text-xl w-max">Starts From</p>
          <p className="mx-5 my-2 font-bold text-xl w-max text-blue-700">
            {`IDR ${new Intl.NumberFormat('ja-JP').format(props.price)} ${props.status === 'daily' ? ' / Night' : ''}`}
          </p>
        </div>
        <button
          className="py-3 w-[90%] bg-red-500 font-bold text-white rounded-2xl m-5"
          onClick={props.handleClose}
        >
          BACK
        </button>
      </div>
    </div>
  );
};

export default DataView;
