import React, { useState, useCallback, useEffect } from 'react';
import getTime from 'routes/api/getTime.js';

const TimePicker = () => {
  const localDateTime =
    JSON.parse(localStorage.getItem('time-reservation')) || {};
  const icNo = localStorage.getItem('ICNO');
  const auth = localStorage.getItem('token');
  const apiURL = localStorage.getItem('api_URL');
  const [time, setTime] = useState(localDateTime.time || '00');
  const [times, setTimes] = useState([]);
  const [minutes, setMinutes] = useState([]);
  const [minute, setMinute] = useState(localDateTime.minute || '00');
  const [showMinute, setShowMinute] = useState(false);
  const [showTime, setShowTime] = useState(true);

  const fetchData = async () => {
    try {
      const response = await getTime(icNo, auth, apiURL);
      if (response) {
        setTimes(
          response[0].Data.ListHour.sort((a, b) => a.hourName - b.hourName)
        );

        setMinutes(
          response[0].Data.ListMinute.sort(
            (a, b) => a.minuteName - b.minuteName
          )
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const currentHour = new Date().getHours();
  const currentMinutes = new Date().getMinutes();

  const handleTimeClick = useCallback(() => {
    setShowTime(true);
    setShowMinute(false);
  }, []);

  const handleMinuteClick = useCallback(() => {
    setShowTime(false);
    setShowMinute(true);
  }, []);

  const selectTime = useCallback((selectedTime) => {
    setTime(selectedTime);
  }, []);

  const selectMinute = useCallback((selectedMinute) => {
    setMinute(String(selectedMinute).padStart(2, '0'));
  }, []);

  useEffect(() => {
    localStorage.setItem(
      'time-reservation',
      JSON.stringify({ ...localDateTime, time, minute })
    );
  }, [time, minute]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="flex mb-10 justify-between">
        <button
          className="px-20 py-4 bg-[#f2f2f2] text-[#004AAD] font-bold text-3xl rounded-full"
          onClick={handleTimeClick}
        >
          TIME
        </button>
        <div className="flex w-max gap-4">
          <input
            type="text"
            value={time}
            readOnly
            onClick={handleTimeClick}
            className={`py-4 bg-[#f2f2f2] text-[#004AAD] font-bold text-3xl rounded-full w-[10vw] text-center ${showTime ? 'border-[#004AAD] border-2' : 'border-transparent'}`}
          />
          <p className="py-4 text-[#004AAD] text-center font-bold text-3xl rounded-full">
            :
          </p>
          <input
            type="text"
            value={minute}
            readOnly
            onClick={handleMinuteClick}
            className={`py-4 bg-[#f2f2f2] text-[#004AAD] font-bold text-3xl rounded-full w-[10vw] text-center ${showMinute ? 'border-[#004AAD] border-2' : 'border-transparent'}`}
          />
        </div>
      </div>

      {showTime && (
        <div className="grid gap-4 grid-cols-8">
          {times.map((t) => {
            const isDisabled =
              parseInt(t.hourName) < currentHour ||
              (parseInt(t.hourName) === currentHour && currentMinutes >= 50);
            return (
              <button
                key={t.dID}
                onClick={() =>
                  !isDisabled && selectTime(String(t.hourName).padStart(2, '0'))
                }
                className={`text-white text-center p-4 rounded-xl font-bold text-xl ${t.hourName == time ? 'bg-[#1A8BAF]' : 'bg-[#004AAD]'} ${isDisabled ? 'bg-[#C0C0C0]' : ''}`}
                disabled={isDisabled}
              >
                {String(t.hourName).padStart(2, '0')}
              </button>
            );
          })}
        </div>
      )}

      {showMinute && (
        <div className="flex gap-4">
          {minutes.map((m) => {
            const isDisabled =
              parseInt(time) === currentHour &&
              parseInt(m.minuteName) <= currentMinutes;
            return (
              <button
                key={m.dID}
                onClick={() =>
                  selectMinute(String(m.minuteName).padStart(2, '0'))
                }
                className={`text-white p-4 rounded-xl font-bold text-xl ${m.minuteName == minute ? 'bg-[#1A8BAF]' : 'bg-[#004AAD]'} ${isDisabled ? 'bg-[#C0C0C0]' : ''}`}
                disabled={isDisabled}
              >
                {String(m.minuteName).padStart(2, '0')}
              </button>
            );
          })}
        </div>
      )}
    </>
  );
};

export default TimePicker;
