import React, { useState, useEffect } from 'react';
import 'animate.css'; // Import Animate.css
import backgroundImage from 'assests/logos/background.png';

const ErrorConnection = () => {
  return (
    <div
      className="bg-brand min-h-screen text-slate-1000 flex justify-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        animation: 'fadeInBackground 1s ease-in-out',
      }}
    >
      <style>
        {`
      @keyframes fadeInBackground {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
      
    `}
      </style>
      <div style={{ minHeight: '25%', minWidth: '50%' }}>
        <h2>Connection Failed To Server</h2>
        <h2>Please Check Your</h2>
        <h2>Connection Settings</h2>
      </div>
    </div>
  );
};

export default ErrorConnection;
