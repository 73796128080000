import axios from 'axios';

const getRoomInventory = async (IcNo, IcKey, date, apiURL) => {
  try {
    const response = await axios.get(
      `${apiURL}/api/web/roominventorymkios?fromdate=${date}&todate=${date}`,
      {
        headers: {
          'Content-Type': 'application/json',
          ICNO: IcNo,
          Authorization: IcKey,
        },
      }
    );

    if (response.status === 200) {
      return response.data; // Mengembalikan data respons langsung
    } else {
      console.error('Unexpected response status:', response.status);
    }
  } catch (error) {
    console.error('Error during request:', error);
  }
};

export default getRoomInventory;
