import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import 'animate.css';
import backgroundImage from 'assests/logos/background.png';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import searchBookingId from 'routes/api/searchBookingId.js';

const SearchBooking = () => {
  const nav = useNavigate();

  const [idInput, setIdInput] = useState('');
  const [error, setError] = useState('');
  const url = localStorage.getItem('api_URL');
  const icno = localStorage.getItem('ICNO');
  const token = localStorage.getItem('token');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await searchBookingId(url, icno, token, idInput);
    const bookingDetail = response.data[0].Data[0];

    try {
      if (response.data[0].Status == 200) {
        localStorage.setItem(
          'date-reservation',
          new Intl.DateTimeFormat('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          }).format(new Date(bookingDetail.DateReserv))
        );

        console.log(bookingDetail);

        localStorage.setItem(
          'time-reservation',
          JSON.stringify({
            time: new Date(bookingDetail.rHourlyStart).getHours(),
            minute: new Date(bookingDetail.rHourlyStart).getMinutes(),
          })
        );
        localStorage.setItem('book_id', bookingDetail.BookingID);
        localStorage.setItem(
          'user',
          JSON.stringify({
            firstName: bookingDetail.FirstName,
            lastName: bookingDetail.LastName,
            country: bookingDetail.CountryID,
            dateOfBirth: '',
            email: bookingDetail.Email,
            address: '',
            note: bookingDetail.Remark,
            city: '',
          })
        );

        if (bookingDetail.rHourlyRateID == 0) {
          localStorage.setItem('history', 'daily');
          localStorage.setItem('durationStay', '1 Night');
        } else {
          localStorage.setItem('history', 'hourly');
          localStorage.setItem('durationStay', bookingDetail.rName);
        }

        localStorage.setItem(
          'room',
          JSON.stringify({
            roomTypeName: bookingDetail.roomType,
            roomID: bookingDetail.roomTypeID,
          })
        );
        localStorage.setItem('room_name', bookingDetail.roomType);

        nav('/checkInSuccessFull');
      } else if (response.data[0].Status == 400) {
        throw new Error('Booking ID is expired or not found');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleClickWalkIn = () => {
    nav('/walkIn');
  };
  return (
    <div
      className="bg-brand min-h-screen text-slate-1000 flex"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: '900px',
        animation: 'fadeInBackground 1s ease-in-out',
        display: 'flex',
        flexDirection: 'column',
        color: '#2BB4D4',
      }}
    >
      <style>
        {`
        @keyframes fadeInBackground {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        input::placeholder{
            color: #2BB4D4;
            font-weight: bold
        }

        input:focus {
          outline: none;
        }

        input:focus::placeholder{
          color: transparent;
        }
      `}
      </style>

      <h1 className="font-bold text-[2rem] m-[1%]">CHECK - IN</h1>

      <form onSubmit={handleSubmit}>
        <p className="text-[red] mx-[1%] font-bold text-[20px]">{error}</p>
        <input
          type="text"
          value={idInput}
          onChange={(e) => setIdInput(e.target.value)}
          placeholder="SEARCH BOOKING ID"
          className="p-2.5 rounded-[25px] text-center text-[1.3rem] font-bold m-[1%] bg-[#E5E4E2] w-[21%]"
        />
        <button type="submit" className="bg-[#E5E4E2] p-[1%] rounded-[35px]">
          <FontAwesomeIcon icon={faSearch} className="font-bold text-[16px]" />
        </button>
      </form>
      <button
        type="text"
        className="p-2.5 rounded-[25px] text-center text-[1.5rem] font-bold m-[1%] bg-[#E5E4E2] w-[21%]"
        onClick={handleClickWalkIn}
      >
        WALK IN
      </button>
    </div>
  );
};

export default SearchBooking;
