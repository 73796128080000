import React, { useState, useEffect, useRef } from 'react';
import 'animate.css'; // Import Animate.css
import backgroundImage from 'assests/logos/background.png';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { faBorderAll } from '@fortawesome/free-solid-svg-icons';

/**
 * HotelsPage Component
 * Renders the hotels page with a similar style to the hero cover section.
 * @returns {JSX.Element} - The HotelsPage component.
 */
const ScanIdCard = () => {
  const [isScanningComplete, setIsScanningComplete] = useState(false);
  const buttonScannerRef = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setIsScanningComplete(true);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (isScanningComplete) {
      document.getElementById('scanner').style.borderColor = '#00B050';
      document.getElementById('textscanner').style.color = '#00B050';
      document.getElementById('tittlescanner').style.color = '#FFFF';
      document.getElementById('tittlescanner').style.backgroundColor = '#00B050';
      document.getElementById('textscanner').innerText = 'SCANNING HAS COMPLETED';
      buttonScannerRef.current.style.backgroundColor = '#00B050';
      buttonScannerRef.current.innerText = 'NEXT';
    }
  }, [isScanningComplete]);

  return (
    <div className="bg-brand min-h-screen text-slate-1000 flex" style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '1000px',
      backgroundPositionX: '900px',
      animation: 'fadeInBackground 1s ease-in-out'
    }}>
      <style>
        {`
          @keyframes fadeInBackground {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
          .modal {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
          }
          .modal-content {
            background: white;
            padding: 20px;
            border-radius: 8px;
            position: relative;
          }
          .close-button {
            position: absolute;
            top: 10px;
            right: 10px;
            background: transparent;
            border: none;
            font-size: 20px;
            cursor: pointer;
          }
        `}
      </style>
      <div className="hero-content__container flex flex-col container mx-auto px-2 md:px-0 mt-1">
        <div className="hero-content__text py-4">
          <h1 style={{ color: '#004AAD', fontWeight: 'bold', fontSize: '75px' }}>BOOKING</h1>
          <h3 id="scanner" className="text-3xl font-medium text-center" style={{ border: '2px solid #004AAD', minHeight: '600px', borderRadius: '10px', padding: '20px' }}>
            <button
              className='px-9 py-2 rounded-full font-montserrat mb-6'
              id="tittlescanner"
              style={{ margin: '0 auto', backgroundColor: '#004AAD', color: '#D9D9D9', width: '800px', fontWeight: 'bold', fontSize: '24px' }}
              disabled
            >
              SCAN ID CARD
            </button>
            <form style={{ maxWidth: '10000px', margin: '0 auto' }}>
              <h1 id="textscanner" className='mt-56' style={{ color: '#004AAD', fontWeight: 'bold', fontSize: '60px' }}>PLEASE PUT YOUR ID CARD ON THE SCANNER</h1>
            </form>
          </h3>
          <div className="mb-4">
            <button
              type="submit"
              id="buttonscanner"
              className="w-full px-6 py-2 mt-4 text-white bg-blue-600 rounded-md hover:bg-blue-700"
              style={{ backgroundColor: '#00B050', fontWeight: 'bold', fontSize: '18px', borderRadius: '20px' }}
              ref={buttonScannerRef}
            >
              SCAN
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScanIdCard;
