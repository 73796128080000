import axios from 'axios';

const authentication = async (icNo, icKey, apiURL) => {
  try {
    const response = await axios.post(
      `${apiURL}/api/web/auth`,
      {
        ICNO: icNo,
        Authorization: icKey,
      }
    );
    // console.log('full response', response);

    if (response.status === 200) {
      return response.data;
    } else {
      console.error('Authentication failed with status code:', response.status);
      return { message: 'Authentication failed', status: response.status };
    }
  } catch (error) {
    console.error('Error during authentication', error);
    return { message: 'Error during authentication', error: error.message };
  }
};

export default authentication;
