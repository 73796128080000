import React from 'react';
import 'animate.css'; // Import Animate.css

import { useNavigate } from 'react-router-dom';

const PaymentSucessfull = () => {
  const nav = useNavigate();
  const date = localStorage.getItem('date-reservation');

  const handleClickHome = () => {
    nav('/');
  };

  const handleClickSucess = () => {
    nav('/checkInSuccess');
  };

  return (
    <div className="bg-brand h-screen text-slate-1000 flex bg-cover bg-center flex-col items-center bg-no-repeat animate-[fadeInBackground] duration-1000 ease-in-out">
      <style>
        {`
          @keyframes fadeInBackground {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          
        `}
      </style>
      <button
        className="font-montserrat my-[1%] bg-[#00B050] text-white h-max w-4/5 rounded-full text-2xl py-3 font-bold"
        disabled
      >
        Payment Succesfull
      </button>
      <button class="bg-[#004AAD] text-white text-lg font-bold w-[70%] p-2 rounded-full h-[6%] relative z-10 top-3">
        E-Voucher
      </button>
      <h3 className="h-[67%] w-[80%] flex justify-between flex-wrap border-2 border-[#004AAD] rounded-lg bg-whitesmoke opacity-80">
        <div className="font-bold m-[2%] m-w-[45%] h-[15%]">
          <p className="text-[180%]">BOOKING ID</p>
          <p className="text-[180%]">{localStorage.getItem('book_id')}</p>
          <p className="m-[5px]">Book and Payable by </p>
          <p className="m-[5px]">Insoft M-kios</p>
        </div>
        <div className="font-bold m-[2%] w-[35%] h-[15%]">
          <h1 className="text-[180%]">
            {JSON.parse(localStorage.getItem('hotel')).name}
          </h1>
          <h1 className="text-[180%]">
            {JSON.parse(localStorage.getItem('hotel')).address}
          </h1>
          <div class="flex min-w-full justify-between">
            <div className="w-1/2 mb-[5%]">
              <p className="m-[5px]">Check In</p>
              <p className="m-[5px]">
                {localStorage.getItem('date-reservation')}
              </p>
            </div>
            <div className="w-1/2 mb-[5%]">
              <p className="m-[5px]">Check Out</p>
              <p className="m-[5px]">
                {new Intl.DateTimeFormat('en-GB', {
                  day: '2-digit',
                  month: 'short',
                  year: 'numeric',
                }).format(
                  new Date(
                    new Date(date).getFullYear(),
                    new Date(date).getMonth(),
                    new Date(date).getDate() + 1
                  )
                )}
              </p>
            </div>
          </div>
        </div>
        <table className="mx-[2%] w-full h-[40%]">
          <thead>
            <td colSpan="5" className="pb-2 font-bold text-3xl">
              Booking Details /<i className="text-[#A9A9A9] ">Detail Pesanan</i>
            </td>
            <tr className="border-t-2 border-solid border-[black] text-left"></tr>

            <tr className="text-left">
              <th className="w-[150px]">No</th>
              <th>Room Type</th>
              <th className="text-center">Guest Name</th>
              <th className="text-center ">Guest Per Room</th>
              <th className="text-center ">Include Breakfast</th>
            </tr>
            <tr className="text-left text-[#A9A9A9] max-h-max">
              <th className="w-[150px]">
                <i>No</i>
              </th>
              <th>
                <i>Tipe Kamar</i>
              </th>
              <th className="text-center">
                <i>Nama Tamu</i>
              </th>
              <th className="text-center">
                <i>Tamu per kamar</i>
              </th>
              <th className="text-center">
                <i>Termasuk sarapan</i>
              </th>
            </tr>
          </thead>
          <tbody className="font-bold">
            <tr className="border-y-2 h-[5%] border-black">
              <td>1</td>
              <td>{`${JSON.parse(localStorage.getItem('rate'))[0].roomType}`}</td>
              <td className="text-center">{`${JSON.parse(localStorage.getItem('user')).firstName} ${JSON.parse(localStorage.getItem('user')).lastName}`}</td>
              <td className="text-center">{`${localStorage.getItem('guest')} Adult(s)`}</td>
              <td className="text-center">Yes</td>
            </tr>
            <tr>
              <td className="h-[20px]" colSpan={2}>
                Note
                <i className="text-[#A9A9A9]"> / Catatan</i>
              </td>
            </tr>
            <tr>
              <td className="h-[20px]" colSpan={2}>
                {JSON.parse(localStorage.getItem('user')).note}
              </td>
            </tr>
          </tbody>
        </table>
      </h3>
      <div className="w-4/5 flex justify-between mt-[1%] text-white font-bold">
        <button className="bg-[#A9A9A9] w-1/2 mr-4 py-[1%] px-[6%] rounded-full text-white">
          PRINT E - VOUCHER
        </button>
        <button
          className="bg-[#2BB4D4] w-1/2 py-[1%] ml-4 px-[6%] rounded-full text-white"
          onClick={handleClickHome}
        >
          HOME
        </button>
      </div>
    </div>
  );
};

export default PaymentSucessfull;
