import React from 'react';
import 'animate.css'; // Import Animate.css
import { useNavigate } from 'react-router-dom';

const CheckInPaymentSuccessFull = () => {
  const nav = useNavigate();

  const date = localStorage.getItem('date-reservation');
  const isHourly = localStorage.getItem('history') === 'hourly';

  const { minute, time: hour } = JSON.parse(
    localStorage.getItem('time-reservation')
  );
  const durationStayHours = parseInt(localStorage.getItem('durationStay'), 10);
  const checkInDate = new Date(localStorage.getItem('date-reservation'));
  const checkInHourlyDate = new Date(checkInDate);

  checkInHourlyDate.setHours(hour);
  checkInHourlyDate.setMinutes(minute);

  const durationInMilliseconds = durationStayHours * 60 * 60 * 1000;
  const checkOutHourlyDate = new Date(
    checkInHourlyDate.getTime() + durationInMilliseconds
  );

  const handleClickSuccess = async () => {
    nav('/checkInSuccess');
  };

  return (
    <div className="bg-brand h-screen text-slate-1000 flex bg-cover bg-center flex-col items-center bg-no-repeat animate-[fadeInBackground] duration-1000 ease-in-out">
      <style>
        {`
          @keyframes fadeInBackground {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
        `}
      </style>
      <button
        className="font-montserrat my-[1%] bg-[#00B050] text-white h-max w-4/5 rounded-full text-2xl py-3 font-bold"
        disabled
      >
        Payment Successful
      </button>
      <button className="bg-[#2BB4D4] text-white text-lg font-bold w-[70%] p-2 rounded-full h-[6%] relative z-10 top-3">
        E-Voucher
      </button>
      <h3 className="h-[67%] w-[80%] flex justify-between flex-wrap border-2 border-[#2BB4D4] rounded-lg bg-whitesmoke opacity-80">
        <div className="font-bold m-[2%] m-w-[45%] h-[15%]">
          <p className="text-[180%]">Room Details</p>
          <p className="text-[180%]">
            Room No {localStorage.getItem('room_number')}
          </p>
          <p className="m-[5px]">Book and Payable by</p>
          <p className="m-[5px]">Insoft M-kios</p>
        </div>
        <div className="font-bold m-[2%] w-[35%] h-[15%]">
          <h1 className="text-[180%]">
            {JSON.parse(localStorage.getItem('hotel')).name}
          </h1>
          <h1 className="text-[180%]">
            {JSON.parse(localStorage.getItem('hotel')).address}
          </h1>
          <div className="flex min-w-full justify-between">
            <div className="w-1/2 mb-[5%]">
              <p className="m-[5px]">Check In</p>
              <p className="m-[5px]">
                {isHourly ? (
                  <>
                    <p>
                      {new Intl.DateTimeFormat('en-GB', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                      }).format(checkInDate)}
                    </p>
                    <p>
                      {String(checkInHourlyDate.getHours()).padStart(2, '0')} :
                      {String(checkInHourlyDate.getMinutes()).padStart(2, '0')}
                    </p>
                  </>
                ) : (
                  new Intl.DateTimeFormat('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                  }).format(checkInDate)
                )}
              </p>
            </div>
            <div className="w-1/2 mb-[5%]">
              <p className="m-[5px]">Check Out</p>
              <p className="m-[5px]">
                {isHourly ? (
                  <>
                    <p>
                      {new Intl.DateTimeFormat('en-GB', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                      }).format(checkOutHourlyDate)}
                    </p>
                    <p>
                      {String(checkOutHourlyDate.getHours()).padStart(2, '0')} :
                      {String(checkOutHourlyDate.getMinutes()).padStart(2, '0')}
                    </p>
                  </>
                ) : (
                  new Intl.DateTimeFormat('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                  }).format(
                    new Date(
                      new Date(date).setDate(new Date(date).getDate() + 1)
                    )
                  )
                )}
              </p>
            </div>
          </div>
        </div>
        <table className="mx-[2%] w-full h-[40%]">
          <thead>
            <tr>
              <td colSpan="5" className="pb-2 font-bold text-3xl">
                Booking Details /
                <i className="text-[#A9A9A9]">Detail Pesanan</i>
              </td>
            </tr>
            <tr className="border-t-2 border-solid border-[black] text-left"></tr>
            <tr className="text-left">
              <th className="w-[150px]">No</th>
              <th>Room Type</th>
              <th className="text-center">Guest Name</th>
              <th className="text-center">Guest Per Room</th>
              <th className="text-center">Night/Hour</th>
            </tr>
            <tr className="text-left text-[#A9A9A9] max-h-max">
              <th className="w-[150px]">
                <i>No</i>
              </th>
              <th>
                <i>Tipe Kamar</i>
              </th>
              <th className="text-center">
                <i>Nama Tamu</i>
              </th>
              <th className="text-center">
                <i>Tamu Per Kamar</i>
              </th>
              <th className="text-center">
                <i>Malam/Jam</i>
              </th>
            </tr>
          </thead>
          <tbody className="font-bold">
            <tr className="border-y-2 h-[5%] border-black">
              <td>1</td>
              <td>{localStorage.getItem('room_name')}</td>
              <td className="text-center">
                {`${JSON.parse(localStorage.getItem('user')).firstName} ${JSON.parse(localStorage.getItem('user')).lastName}`}
              </td>
              <td className="text-center">{`${localStorage.getItem('guest')} Adult(s)`}</td>
              <td className="text-center">
                {localStorage.getItem('durationStay')}
              </td>
            </tr>
            <tr>
              <td className="h-[20px]" colSpan={2}>
                Note
                <i className="text-[#A9A9A9]"> / Catatan</i>
              </td>
            </tr>
            <tr>
              <td className="h-[20px]" colSpan={2}>
                {JSON.parse(localStorage.getItem('user')).note}
              </td>
            </tr>
          </tbody>
        </table>
      </h3>
      <div className="w-4/5 flex justify-between mt-[1%] text-white font-bold">
        <button className="bg-[#A9A9A9] py-[1%] px-[6%] rounded-full text-white w-2/5">
          PRINT E - VOUCHER
        </button>
        <button
          className="bg-[#00B050] py-[1%] px-[6%] rounded-full text-white  w-2/5"
          onClick={handleClickSuccess}
        >
          NEXT
        </button>
      </div>
    </div>
  );
};

export default CheckInPaymentSuccessFull;
