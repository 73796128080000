import axios from 'axios';
import FormData from 'form-data';

const postPaymentHourly = async (apiURL, icNo, authorization, formData) => {
  const bodyFormData = new FormData();

  console.log('hourly');

  bodyFormData.append('first_name', formData.firstName);
  bodyFormData.append('last_name', formData.lastName);
  bodyFormData.append('birth_date', formData.birthDate);
  bodyFormData.append('address', formData.address);
  bodyFormData.append('country_id', formData.countryID);
  bodyFormData.append('city', formData.city);
  bodyFormData.append('email', formData.email);
  bodyFormData.append('arrival_date', formData.dateArrival);
  bodyFormData.append('departure_date', formData.depatureDate);
  bodyFormData.append('phone', formData.phone);
  bodyFormData.append('roomtype_id', formData.roomtypeID);
  bodyFormData.append('tariff_id', formData.tariffId);
  bodyFormData.append('rate_amount', formData.rateAmount);
  bodyFormData.append('hourlyrate_id', formData.hourlyRateId);
  bodyFormData.append('hourlysession_id', formData.hourlySessionId);
  bodyFormData.append('hourly_start', formData.hourlyStart);
  bodyFormData.append('hourly_end', formData.hourlyEnd);
  bodyFormData.append('additional_info', formData.note);
  bodyFormData.append('reg_token', formData.token);

  console.log(bodyFormData, 'inoi data');
  try {
    const response = await axios.post(
      `${apiURL}/api/web/walkinmkios`,
      bodyFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          ICNO: icNo,
          Authorization: authorization,
        },
      }
    );

    if (response.status === 200) {
      return response;
    } else {
      console.error('Unexpected reservation response status:', response.status);
      return null;
    }
  } catch (e) {
    console.error('Error during reservation request:', e.message);
    return null;
  }
};

export default postPaymentHourly;
