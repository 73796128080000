const ModalDanger = (props) => {
  return (
    <div className="w-full flex flex-col justify-center items-center h-full">
      <h1 className="mb-10 font-bold text-3xl w-4/5 text-center">
        Your session is already Ended wanna req for new?
      </h1>
      <div className="w-4/5 flex items-center justify-around ">
        <button
          onClick={props.hanldeBack}
          className="bg-red-600 text-white p-4 w-1/4 h-max font-bold hover:bg-white hover:border-red-600 hover:text-red-600 focus:outline-none focus:border-red-600 rounded-xl border border-transparent"
        >
          Back
        </button>
        <button
          onClick={props.hanldeContinue}
          className="bg-blue-600 text-white font-bold p-4 w-1/4 h-max hover:bg-white hover:border-blue-600 hover:text-blue-600 focus:outline-none focus:border-blue-600 rounded-xl border border-transparent"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default ModalDanger;
