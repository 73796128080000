import React from 'react';
import 'animate.css';
import backgroundImage from 'assests/logos/background.png';
import { useNavigate } from 'react-router-dom';
import checkIn from 'routes/api/checkIn';

import { useEffect } from 'react';

const CheckInSuccess = () => {
  const nav = useNavigate();

  const handleClick = () => {
    nav('/');
  };
  useEffect(() => {
    const formData = {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      address: '',
      country: '',
      phoneNumber: '',
      city: '',
      email: '',
      note: '',
    };

    localStorage.setItem('user', JSON.stringify(formData));
    localStorage.setItem('price', '');
    localStorage.setItem(
      'room',
      JSON.stringify({ roomTypeName: '', roomID: '' })
    );
  }, []);

  // useEffect(() => {
  //   const IcNo = localStorage.getItem('ICNO');
  //   const authToken = localStorage.getItem('token');
  //   const apiURL = localStorage.getItem('api_URL');
  //   const BookID = localStorage.getItem('book_id');

  //   const GetRoomDetail = async () => {
  //     try {
  //       const res = await checkIn(IcNo,authToken,apiURL, BookID);
  //       if (res.Status == 200) {
  //         setRoomNo(res.data[0].Room || '');
  //       }
  //       console.log(res);
  //     } catch (error) {
  //       console.error('Error fetching Room Detail:', error);
  //     }
  //   };
  //   GetRoomDetail()
  // }, []);

  return (
    <div
      className="bg-brand h-screen text-slate-1000 flex"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: '100vw',
        backgroundPosition: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundRepeat: 'no-repeat',
        animation: 'fadeInBackground 1s ease-in-out',
      }}
    >
      <style>
        {`
        @keyframes fadeInBackground {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        
      `}
      </style>
      <button className="bg-[#00B050] text-white text-[28px] font-bold w-[70%] p-[1%] rounded-2xl h-[8%] z-10  absolute top-20">
        YOU'RE CHECK-IN
      </button>
      <h3
        className="
        h-2/3
        w-4/5
        flex 
        flex-col
        mt-10
        justify-center
        border-2
        border-[#00B050]
        rounded-[25px]
        flex-wrap
        bg-whitesmoke
        opacity-80
        items-center
        relative
        top-5
      "
      >
        <h1 className="text-6xl text-center font-bold text-[#00B050] p-4">
          YOUR ROOM KEY HAS BEEN PRINTED, PLEASE TAKE IT. THANK YOU
        </h1>
        <h1 className="text-[4rem] text-center font-bold text-blue-500 p-6">
          YOUR ROOM IS {localStorage.getItem('room_number')}
        </h1>
        <p className="text-[red] font-bold text-[1.2rem]">
          NOTE: IF YOU HAVE ANY PROBLEMS, PLEASE VISIT THE RECEPTIONIST
          IMMEDIATELY
        </p>
      </h3>
      <button
        onClick={handleClick}
        className="bg-[#00B050] text-white w-[80%] py-[1%] my-[3%] rounded-full font-bold text-[24px]"
      >
        HOME
      </button>
    </div>
  );
};

export default CheckInSuccess;
