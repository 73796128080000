import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import HotelsSearch from './routes/listings/booking/HotelsSearch';
import ConfirmOrders from './routes/listings/booking/ConfirmOrders';
import ScanIdCard from './routes/listings/booking/ScanIdCard';
import UserProfile from './routes/user-profile/UserProfile';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import Home from './routes/home/Home';
import { AuthProvider } from './contexts/AuthContext';

import HotelDetails from './routes/hotel-details/HotelDetails';
import Login from './routes/login/Login';
import Register from './routes/register/Register';
import AboutUs from './routes/about-us/AboutUs';
import BaseLayout from './routes/layouts/base-layout/BaseLayout';
import ForgotPassword from './routes/forgot-password/ForgotPassword';
import CheckOut from 'routes/listings/booking/CheckOut';
import BookingConfirmation from 'routes/booking-confimation/BookingConifrmation';
import OrderDetails from 'routes/listings/booking/OrderDetails';
import PaymentSucessfull from 'routes/listings/booking/PaymentSucessfull';
import CheckInSuccess from 'routes/listings/booking/CheckInSucess';
import SearchBooking from 'routes/listings/checkIn/SearchBooking';
import WalkIn from 'routes/listings/checkIn/WalkIn';
import ErrorConnection from 'routes/listings/System/ErrorConnection';
import CheckInDetails from 'routes/listings/checkIn/checkInDetails';
import ConfirmOrderCheckIn from 'routes/listings/checkIn/confirmOrderCheckIn';
import CheckOutChekIn from 'routes/listings/checkIn/CheckOutCheckIn';
import CheckInPaymentSuccessFull from 'routes/listings/checkIn/CheckInPaymentSuccesfull';

// if (process.env.NODE_ENV === 'development') {
//   makeServer();
// }

// makeServer();

const router = createBrowserRouter([
  {
    path: '/',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/booking',
        element: <HotelsSearch />,
      },
      {
        path: '/ConfirmOrders',
        element: <ConfirmOrders />,
      },
      {
        path: '/ScanIdCard',
        element: <ScanIdCard />,
      },
      {
        path: '/about-us',
        element: <AboutUs />,
      },
      {
        path: '/user-profile',
        element: <UserProfile />,
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/register',
        element: <Register />,
      },
      {
        path: '/hotel/:hotelId',
        element: <HotelDetails />,
      },
      {
        path: '/forgot-password',
        element: <ForgotPassword />,
      },

      {
        path: '/booking-confirmation',
        element: <BookingConfirmation />,
      },
      {
        path: '/orderDetails',
        element: <OrderDetails />,
      },
      {
        path: '/checkOut',
        element: <CheckOut />,
      },
      {
        path: '/paymentSuccessfull',
        element: <PaymentSucessfull />,
      },
      {
        path: '/checkInSuccess',
        element: <CheckInSuccess />,
      },
      {
        path: '/checkIn',
        element: <SearchBooking />,
      },

      {
        path: '/walkIn',
        element: <WalkIn />,
      },
      {
        path: '/errorConnection',
        element: <ErrorConnection />,
      },
      {
        path: '/confirmCheckIn',
        element: <ConfirmOrderCheckIn />,
      },
      {
        path: '/checkInDetails',
        element: <CheckInDetails />,
      },
      {
        path: '/checkOutCheckIn',
        element: <CheckOutChekIn />,
      },
      {
        path: '/checkInSuccessFull',
        element: <CheckInPaymentSuccessFull />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <AuthProvider>
    <RouterProvider router={router} />
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
