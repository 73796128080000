import React, { useEffect, useState } from 'react';
import backgroundImage from 'assests/logos/background.png'; // Memperbaiki path untuk background image
import 'animate.css';
import Modal from 'react-modal';
import { Alert } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';
import postPayment from 'routes/api/postPayment';
import postPaymentHourly from 'routes/api/postPaymentHourly';
import Loading from '../components/loading';
import { v4 } from 'uuid';
import Failed from '../components/Failed';
import ModalDanger from '../components/ModalDanger';
import authentication from 'routes/api/Authentification';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="h-6 w-6"
    >
      <path
        fillRule="evenodd"
        d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
        clipRule="evenodd"
      />
    </svg>
  );
}

const CheckOutChekIn = () => {
  const [open, setOpen] = React.useState(false);
  const [cardNumber, setCardNumber] = useState('');
  const [ErrorModal, setErrorModal] = useState('');
  const [validDate, setValidDate] = useState('');
  const [CVV, setCVV] = useState('');

  const [paymentMethod, setPaymentMethod] = useState('');
  const authorize = localStorage.getItem('Authorization');
  const icNo = localStorage.getItem('ICNO');
  const token = localStorage.getItem('token');
  const url = localStorage.getItem('api_URL');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const room = JSON.parse(localStorage.getItem('room'));
  const isHourly = localStorage.getItem('history') == 'hourly';
  const checkInDate = new Date(localStorage.getItem('date-reservation'));
  const nav = useNavigate();

  const durationStayHours = parseInt(localStorage.getItem('durationStay'), 10);

  const checkInHourlyDate = new Date();

  const handleClick = () => {
    setError(false);
  };

  const durationInMilliseconds = durationStayHours * 60 * 60 * 1000;
  const checkOutHourlyDate = new Date(
    checkInHourlyDate.getTime() + durationInMilliseconds
  );

  const formattedCheckInDate = `${checkInDate.getFullYear()}-${String(checkInDate.getMonth() + 1).padStart(2, '0')}-${String(checkInDate.getDate()).padStart(2, '0')}`;

  const formattedCheckInDateHourlyDate = `${checkInHourlyDate.getFullYear()}-${String(checkInHourlyDate.getMonth() + 1).padStart(2, '0')}-${String(checkInHourlyDate.getDate()).padStart(2, '0')} ${String(checkInHourlyDate.getHours()).padStart(2, '0')}:${String(checkInHourlyDate.getMinutes()).padStart(2, '0')}`;

  const formattedCheckOutDateHourlyDate = `${checkOutHourlyDate.getFullYear()}-${String(checkOutHourlyDate.getMonth() + 1).padStart(2, '0')}-${String(checkOutHourlyDate.getDate()).padStart(2, '0')} ${String(checkOutHourlyDate.getHours()).padStart(2, '0')}:${String(checkOutHourlyDate.getMinutes()).padStart(2, '0')}`;
  const tariff = JSON.parse(localStorage.getItem('rate'))[0];

  const user = JSON.parse(localStorage.getItem('user'));

  const leftDate = new Date(
    new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    }).format(
      new Date(
        new Date(checkInDate).getFullYear(),
        new Date(checkInDate).getMonth(),
        new Date(checkInDate).getDate() + 1
      )
    )
  );

  const formatedCheckOutDate = `${new Date(leftDate).getFullYear()}-${String(leftDate.getMonth() + 1).padStart(2, '0')}-${String(leftDate.getDate()).padStart(2, '0')}`;

  useEffect(() => {
    console.log(formattedCheckOutDateHourlyDate);
    localStorage.setItem(
      'time-reservation',
      JSON.stringify({
        time: String(checkInHourlyDate.getHours()).padStart(2, '0'),
        minute: String(checkInHourlyDate.getMinutes()).padStart(2, '0'),
      })
    );
  }, []);

  const handleBack = () => nav('/checkInDetails');
  const handlePaymentBack = () => setPaymentMethod('');

  const handleSubmit = async (e) => {
    try {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);

      e.preventDefault();
      if (!isHourly) {
        await handleSubmitDaily(e);
      } else if (isHourly) {
        await handleSubmitHourly(e);
      }
    } catch (error) {
      setError(true);
    }
  };

  const handleBackClick = () => {
    nav('/');
  };

  const handleContinueClick = async () => {
    try {
      const data = await authentication(icNo, authorize, url);
      if (data) {
        console.log(data);

        localStorage.setItem('token', data[0].Data[0].token);
        localStorage.setItem(
          'hotel',
          JSON.stringify({
            name: data[0].Data[0].property,
            address: data[0].Data[0].address,
          })
        );

        window.location.reload();
      } else {
        console.error('Authentication failed');
      }
    } catch (error) {
      console.error('Error during authentication', error);
    }

    setError(false);
  };

  const handleSubmitDaily = async (e) => {
    e.preventDefault();
    const formData = {
      firstName: user.firstName,
      lastName: user.lastName,
      birthDate: user.dateOfBirth,
      address: user.address,
      countryID: user.country,
      city: user.city,
      email: user.email,
      dateArrival: formattedCheckInDate,
      depatureDate: formatedCheckOutDate,
      phone: user.phoneNumber,
      roomtypeID: room.roomID,
      tariffId: tariff.tariffID,
      rateAmount: localStorage.getItem('price'),
      note: user.note,
      token: v4(),
    };

    const response = await postPayment(url, icNo, token, formData);

    console.log(response.data[0].Status);
    if (response.data[0].Status == '200') {
      localStorage.setItem('room_number', response.data[0].Data[0].Room);

      nav('/checkInSuccessFull');
    } else if (response.data[0].Status == '400') {
      console.log('masuk sini');
      const str = response.data[0].Data;
      const newStr = str.substring(str.indexOf(':') + 4);
      setError(true);
      setErrorModal(newStr);
      setOpen(true);
    }
    //BKID-9E2908069128FO
    // Add your form submission logic here
  };

  const handleSubmitHourly = async (e) => {
    e.preventDefault();
    const formData2 = {
      firstName: user.firstName,
      lastName: user.lastName,
      birthDate: user.dateOfBirth,
      address: user.address,
      countryID: user.country,
      city: user.city,
      email: user.email,
      dateArrival: formattedCheckInDateHourlyDate,
      depatureDate: formattedCheckOutDateHourlyDate,
      phone: user.phoneNumber,
      roomtypeID: room.roomID,
      tariffId: JSON.parse(localStorage.getItem('hourlyRate')).hourly_tarifID,
      rateAmount: localStorage.getItem('price'),
      note: user.note,
      hourlyRateId: JSON.parse(localStorage.getItem('hourlyRate'))
        .hourlyrate_id,
      hourlySessionId: JSON.parse(localStorage.getItem('hourlyRate'))
        .hourlysession_id,
      hourlyStart: formattedCheckInDateHourlyDate,
      hourlyEnd: formattedCheckOutDateHourlyDate,
      token: v4(),
    };

    const res = await postPaymentHourly(url, icNo, token, formData2);

    localStorage.setItem('room_number', res.data[0].Data[0].Room);

    console.log(res);
    try {
      if (res.data[0].Status == '200') {
        nav('/checkInSuccessFull');
      } else if (res.data[0].Status == '400') {
        console.log('masuk sini');
        setError(true);
      }
    } catch (e) {
      setError(true);
    }
  };

  return (
    <div
      className="bg-brand min-h-screen text-slate-1000 flex flex-col bg-cover bg-center bg-no-repeat animate-[fadeInBackground_1s_ease-in-0ut]"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <style>
        {`
          @keyframes  {
            from { opacity: 0; }
            to { opacity: 1; }
          }
        `}
      </style>
      <h1 className="text-[#2BB4D4] font-bold text-2xl ml-10">CHECKOUT</h1>
      <div className="flex">
        <div className="hero-content__container flex flex-col container mx-auto px-2 md:px-0 mt-1 ml-10 h-auto">
          <div className="hero-content__text">
            <h3 className="text-3xl font-medium text-center border-2 border-[#2BB4D4] bg-[whitesmoke] opacity-80 rounded-lg p-5 min-w-[25%]">
              <button
                className="px-9 py-2 rounded-full bg-[#2BB4D4] text-white font-bold text-lg w-3/4"
                disabled
              >
                Contact Detail E-Voucher
              </button>
              <form className="max-w-[1000px] mx-auto mt-4">
                <div className="grid grid-cols-2 gap-4">
                  <div className="mb-4">
                    <label
                      htmlFor="first-name"
                      className="block text-left text-base font-bold text-gray-700"
                    >
                      First Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="first-name"
                      name="first-name"
                      className="mt-1 p-2 w-full border border-gray-300 rounded-md text-sm"
                      placeholder="Enter your first name"
                      value={user.firstName}
                      disabled
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="phone"
                      className="block text-left text-base font-bold text-gray-700"
                    >
                      Phone Number <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      className="mt-1 p-2 w-full border border-gray-300 rounded-md text-sm"
                      placeholder="Enter your phone number"
                      value={user.phoneNumber}
                      disabled
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="last-name"
                      className="block text-left text-base font-bold text-gray-700"
                    >
                      Last Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="last-name"
                      name="last-name"
                      className="mt-1 p-2 w-full border border-gray-300 rounded-md text-sm"
                      placeholder="Enter your last name"
                      value={user.lastName}
                      disabled
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block text-left text-base font-bold text-gray-700"
                    >
                      Email <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      className="mt-1 p-2 w-full border border-gray-300 rounded-md text-sm"
                      placeholder="Enter your email"
                      value={user.email}
                      disabled
                    />
                  </div>
                </div>

                <div className="border-2 border-[#2BB4D4] min-h-[50%] rounded-lg p-5 mt-6 bg-whitesmoke opacity-80 text-lg text-center flex flex-col justify-between">
                  <table className="w-full">
                    <thead>
                      <tr className="border-b-2 border-black">
                        <th className="text-left">Description</th>
                        <th>Qty</th>
                        <th>Night/Hour</th>
                        <th className="text-right">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-left">{room.roomTypeName}</td>
                        <td>1</td>
                        <td>{localStorage.getItem('durationStay')}</td>
                        <td className="text-right">
                          {new Intl.NumberFormat('ja-JP', {
                            style: 'currency',
                            currency: 'IDR',
                            minimumFractionDigits: 0,
                          }).format(localStorage.getItem('price'))}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="text-left">
                    <h2 className="text-red-500 text-sm">
                      {isHourly
                        ? `${new Intl.DateTimeFormat('en-GB', {
                            day: '2-digit',
                            month: 'short',
                            year: 'numeric',
                          }).format(
                            new Date(checkInDate)
                          )} - ${new Intl.DateTimeFormat('en-GB', {
                            day: '2-digit',
                            month: 'short',
                            year: 'numeric',
                          }).format(new Date(checkInDate))}`
                        : `${new Intl.DateTimeFormat('en-GB', {
                            day: '2-digit',
                            month: 'short',
                            year: 'numeric',
                          }).format(new Date(checkInDate))}
                            -
                        ${new Intl.DateTimeFormat('en-GB', {
                          day: '2-digit',
                          month: 'short',
                          year: 'numeric',
                        }).format(new Date(leftDate))}`}
                    </h2>
                    <div className="flex justify-between">
                      <h2 className="font-bold text-xl">TOTAL</h2>
                      <h2 className="font-bold text-xl">
                        {new Intl.NumberFormat('ja-JP', {
                          style: 'currency',
                          currency: 'IDR',
                          minimumFractionDigits: 0,
                        }).format(localStorage.getItem('price'))}
                      </h2>
                    </div>
                  </div>
                </div>
              </form>
              <button
                className="bg-red-500 text-white font-bold text-lg rounded-full w-full mt-2 py-2 "
                onClick={handleBack}
              >
                BACK
              </button>
            </h3>
          </div>
        </div>

        <div className="hero-content__container flex flex-col container mx-auto px-2 md:px-0 mr-6 ml-10 font-bold">
          <div className="border-2 border-[#2BB4D4] bg-[whitesmoke] opacity-80 rounded-lg px-3 flex flex-col items-center text-lg min-h-full justify-between">
            <button
              className="px-9 py-2 rounded-full bg-[#2BB4D4] text-white font-bold w-3/4 mt-5"
              disabled
            >
              Payment Methode
            </button>

            {paymentMethod === 'Credit / Debit Card' ? (
              <>
                <button
                  className="my-1 cursor-pointer px-9 py-2 w-3/4 rounded-full border-2 border-[#2BB4D4] text-[#2BB4D4]"
                  onClick={() => setPaymentMethod('')}
                >
                  Credit / Debit Card
                </button>
                <form className="w-11/12 mx-auto" onSubmit={handleSubmit}>
                  <label
                    htmlFor="cardNumber"
                    className="block text-left text-base font-bold text-gray-700"
                  >
                    Card Number <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="cardNumber"
                    name="cardNumber"
                    className="mt-1 p-2 w-full border border-gray-300 rounded-md text-sm mb-2"
                    placeholder="1111 – 2222 – 3333 – 4444"
                    value={cardNumber}
                    onChange={(e) => setCardNumber(e.target.value)}
                  />
                  <div className="flex">
                    <div className="w-1/2 pr-2">
                      <label
                        htmlFor="validDate"
                        className="block text-left text-base font-bold text-gray-700"
                      >
                        Valid Until <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="date"
                        id="validDate"
                        name="validDate"
                        className="mt-1 p-2 w-full border border-gray-300 rounded-md text-sm"
                        value={validDate}
                        onChange={(e) => setValidDate(e.target.value)}
                      />
                    </div>
                    <div className="w-1/2 pl-2">
                      <label
                        htmlFor="CVV"
                        className="block text-left text-base font-bold text-gray-700"
                      >
                        CVV <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="number"
                        id="CVV"
                        name="CVV"
                        className="mt-1 p-2 w-full border border-gray-300 rounded-md text-sm"
                        placeholder="CVV"
                        value={CVV}
                        onChange={(e) => setCVV(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="my-8 flex justify-between">
                    <button
                      className="bg-red-500 text-white font-bold text-lg rounded-full w-1/2 py-2 mr-5"
                      onClick={handlePaymentBack}
                    >
                      BACK
                    </button>
                    <button
                      type="submit"
                      className="bg-green-500 text-white font-bold text-lg rounded-full w-1/2 py-2"
                    >
                      PAYMENT
                    </button>
                  </div>
                </form>
              </>
            ) : paymentMethod === 'Pay At Hotel' ? (
              <>
                <button
                  className="my-1 cursor-pointer px-9 py-2 w-3/4 rounded-full border-2 border-[#2BB4D4] text-[#2BB4D4]"
                  onClick={() => setPaymentMethod('')}
                >
                  Pay at Hotel
                </button>
                <form className="w-11/12 mx-auto" onSubmit={handleSubmit}>
                  <p className="mt-4 text-center">
                    You have selected to pay at the hotel Receptionist
                  </p>
                  <p className="mt-4 text-center text-[#2BB4D4]">
                    {new Intl.NumberFormat('ja-JP', {
                      style: 'currency',
                      currency: 'IDR',
                      minimumFractionDigits: 0,
                    }).format(localStorage.getItem('price'))}
                  </p>
                  <div className="my-8 flex justify-between">
                    <button
                      className="bg-red-500 text-white font-bold text-lg rounded-full w-1/2 py-2 mr-5"
                      onClick={handlePaymentBack}
                    >
                      BACK
                    </button>
                    <button
                      type="submit"
                      className="bg-green-500 text-white font-bold text-lg rounded-full w-1/2 py-2"
                    >
                      PAYMENT
                    </button>
                  </div>
                </form>
              </>
            ) : (
              <>
                <button
                  className="my-1"
                  onClick={() => setPaymentMethod('Credit / Debit Card')}
                >
                  Credit Card / Debit Card
                </button>
                <button className="my-1">Bank Transfer</button>
                <button className="my-1">Virtual Account</button>
                <button className="my-1">Qris</button>
                <button className="my-1">Gopay</button>
                <button className="my-1">E-wallet</button>
                <button
                  className="my-1"
                  onClick={() => setPaymentMethod('Pay At Hotel')}
                >
                  Pay at Hotel
                </button>
                <button
                  type="submit"
                  className="bg-gray-400 text-white font-bold text-lg rounded-full w-full my-4 py-2"
                  disabled
                >
                  PAYMENT
                </button>
              </>
            )}
            <Alert
              open={open}
              onClose={() => setOpen(false)}
              icon={<Icon />}
              className="rounded-none border-l-4 border-[#2ec946] bg-[#2ec946]/10 font-medium text-[#2ec946]"
              animate={{
                mount: { y: 0 },
                unmount: { y: 100 },
              }}
            >
              {ErrorModal}
            </Alert>
            <Modal
              isOpen={isLoading}
              style={{
                overlay: {
                  backgroundColor: 'rgba(0, 0, 0, 0.75)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
                content: {
                  width: '200px',
                  height: '200px',
                  margin: 'auto',
                  border: 'none',
                  display: 'flex',
                  padding: '0',
                  borderRadius: '10px',
                  overflowY: 'hidden',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
              }}
            >
              <Loading />
            </Modal>

            <Modal
              isOpen={error}
              style={{
                overlay: {
                  backgroundColor: 'rgba(0, 0, 0, 0.75)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
                content: {
                  margin: 'auto',
                  border: 'none',
                  padding: '0',
                  borderRadius: '10px',
                  overflowY: 'hidden',
                },
              }}
            >
              <ModalDanger
                hanldeBack={handleBackClick}
                hanldeContinue={handleContinueClick}
              />
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckOutChekIn;
