import React, { useState, useEffect } from 'react';
import 'animate.css'; // Import Animate.css
import backgroundImage from 'assests/logos/background.png';
import img from '../../../assests/logos/openBackground.png';
import { useNavigate } from 'react-router-dom';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import Modal from 'react-modal';
import DataView from '../components/DataView';

const OrderDetails = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const nav = useNavigate();
  const date = localStorage.getItem('date-reservation');
  const images = JSON.parse(localStorage.getItem('imagesList'));
  const [modalDetail, setModalDetail] = useState(false);
  const room = JSON.parse(localStorage.getItem('room')).roomTypeName;

  const handleModalBack = () => {
    setModalDetail(false);
  };

  const handleClick = () => {
    setModalDetail(true);
  };

  const handleSubmit = () => {
    nav('/checkOut');
  };

  const handleBack = () => {
    nav('/ConfirmOrders');
  };

  useEffect(() => {
    const { firstName, lastName, phoneNumber, email } = JSON.parse(
      localStorage.getItem('user')
    );

    setFirstName(firstName);
    setLastName(lastName);
    setPhoneNumber(phoneNumber);
    setEmail(email);
  }, []);

  return (
    <div
      className="bg-brand h-[800vh] text-slate-900 flex bg-[length:70%_13%] bg-top bg-no-repeat animate-[fadeInBackground] duration-1000 ease-in-out"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <style>
        {`
          @keyframes fadeInBackground {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
          
        `}
      </style>

      <div className="hero-content__container flex flex-col container mx-auto mr-0 px-2 md:px-0 mt-1 ml-10 h-[10%] w-[60%]">
        <div className="hero-content__text py-4">
          <h1 className="text-[#004AAD] font-bold text-[2rem] w-[347.91px]">
            ORDER DETAILS
          </h1>
          <h3 className="text-3xl font-medium text-center border-2 border-[#004AAD] bg-whitesmoke opacity-80 rounded-lg p-5  min-w-[25%]">
            <button
              className="px-9 py-2 rounded-full font-montserrat mb-6 mx-auto bg-[#004AAD] text-[#D9D9D9] w-3/4 font-bold text-lg"
              disabled
            >
              Contact Detail E-Voucher
            </button>
            <form className="max-w-[1000px] mx-auto" onSubmit={handleSubmit}>
              <div className="grid grid-cols-2 gap-4 mt-4">
                <div className="mb-4">
                  <label
                    htmlFor="first-name"
                    className="block text-left text-base font-bold text-gray-700"
                  >
                    First Name <span className="text-[red]">*</span>
                  </label>
                  <input
                    type="text"
                    id="first-name"
                    name="first-name"
                    className="mt-1 p-2 w-full border border-gray-300 rounded-md text-sm"
                    placeholder="Enter your first name"
                    value={firstName}
                    disabled
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="phone"
                    className="block text-left text-base font-bold text-gray-700"
                  >
                    Phone Number <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    className="mt-1 p-2 w-full border border-gray-300 rounded-md text-sm"
                    placeholder="Enter your phone number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    disabled
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="last-name"
                    className="block text-left text-base font-bold text-gray-700"
                  >
                    Last Name <span className="text-[red]">*</span>
                  </label>
                  <input
                    type="text"
                    id="last-name"
                    name="last-name"
                    className="mt-1 p-2 w-full border border-gray-300 rounded-md text-sm"
                    placeholder="Enter your last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    disabled
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block text-left text-base font-bold text-gray-700"
                  >
                    Email <span className="text-[red]">*</span>
                  </label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    className="mt-1 p-2 w-full border border-gray-300 rounded-md text-sm"
                    placeholder="Enter your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled
                  />
                </div>
              </div>

              <div className="border-2 border-[#004AAD] min-h-[50%] rounded-lg p-5 min-w-[25%] mt-6 bg-[whitesmoke] opacity-80 text-lg text-center flex flex-col justify-between">
                <table className="w-full">
                  <thead>
                    <tr
                      style={{
                        borderBottom: '2px solid black',
                      }}
                    >
                      <th style={{ textAlign: 'left' }}>Description</th>
                      <th>Qty</th>
                      <th>Night/Hour</th>
                      <th style={{ textAlign: 'right' }}>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: 'left' }}>{room}</td>
                      <td>1</td>
                      <td>{localStorage.getItem('durationStay')}</td>
                      <td style={{ textAlign: 'right' }}>
                        {`${new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(localStorage.getItem('price'))}`}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div style={{ textAlign: 'left' }}>
                  <p className="text-[red] text-[15px]">
                    {`${new Intl.DateTimeFormat('en-GB', {
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                    }).format(new Date(date))}
                      -
                    ${new Intl.DateTimeFormat('en-GB', {
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                    }).format(
                      new Date(
                        new Date(date).getFullYear(),
                        new Date(date).getMonth(),
                        new Date(date).getDate() + 1
                      )
                    )}`}
                  </p>
                  <div className="flex justify-between">
                    <h2 className="font-bold text-xl">TOTAL</h2>
                    <h2 className="font-bold text-xl">
                      {`${new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(localStorage.getItem('price'))}`}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <button
                  type="submit"
                  className="bg-red-500 font-bold text-lg w-full text-white mt-6 px-9 py-2 rounded-full"
                  onClick={handleBack}
                >
                  BACK
                </button>
                <button
                  type="submit"
                  className="bg-[#00B050] font-bold text-lg rounded-full w-full text-white mt-6 px-9 py-2"
                >
                  SELECT PAYMENT METHOD
                </button>
              </div>
            </form>
          </h3>
        </div>
      </div>
      <div className="hero-content__container flex flex-col mr-10 px-2 md:px-0 mt-1 ml-10 h-[12.8%] w-[20%] max-h-[630px]">
        <div className="rounded-2xl overflow-hidden h-[45vh] relative mt-16">
          <div
            className="w-[10%]  absolute top-[75%] left-[45%] z-10  rounded-xl bg-white opacity-50"
            onClick={handleClick}
          >
            <img src={img} alt="" />
          </div>

          <Slide
            duration={1000}
            className="relative top-0 rounded-2xl h-max"
            nextArrow={<button className="hidden"></button>}
            prevArrow={<button className="hidden"></button>}
          >
            {images.map((image) => {
              return (
                <div
                  className={
                    ' mb-2 z-0 h-[25vh] pt-[4%] flex items-end w-full bg-center bg-cover bg-no-repeat'
                  }
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                ></div>
              );
            })}
          </Slide>
        </div>
        <div className="border-2 border-[#004AAD] bg-whitesmoke opacity-80 mt-3 rounded-lg px-3 overflow-scroll h-[100%] ">
          <p className="font-bold">Room Description</p>
          {localStorage
            .getItem('desc')
            .split('\n')
            .map((text, i) => {
              return (
                <p
                  key={i}
                  style={{
                    marginBlock: '2%',
                    fontSize: '16px',
                  }}
                >
                  {text}
                </p>
              );
            })}
        </div>
      </div>
      <Modal
        isOpen={modalDetail}
        onRequestClose={handleModalBack}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          content: {
            margin: 'auto',
            border: 'none',
            padding: '0',
            borderRadius: '10px',
            overflowY: 'hidden',
          },
        }}
      >
        <DataView
          price={localStorage.getItem('price')}
          room={room}
          imageList={images}
          description={localStorage.getItem('desc')}
          handleClose={handleModalBack}
        />
      </Modal>
    </div>
  );
};

export default OrderDetails;
