import GlobalSearchBox from 'components/global-search-box/GlobalSearchbox';
import 'animate.css'; // Import Animate.css
import backgroundImage from 'assests/logos/background.png';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authentication from 'routes/api/Authentification';
import Modal from 'react-modal';
/**
 * HeroCover Component
 * Renders the hero cover section of the home page.
 * @param {Object} props - The component props.
 * @param {String} props.locationInputValue - The location input value.
 * @param {String} props.numGuestsInputValue - The number of guests input value.
 * @param {Boolean} props.isDatePickerVisible - The date picker visibility state.
 * @param {Function} props.onLocationChangeInput - The location input change handler.
 * @param {Function} props.onNumGuestsInputChange - The number of guests input change handler.
 * @param {Object} props.dateRange - The date range object.
 * @param {Function} props.onDateChangeHandler - The date change handler.
 * @param {Function} props.onDatePickerIconClick - The date picker icon click handler.
 * @param {Function} props.onSearchButtonAction - The search button click handler.
 * @param {Array} props.locationTypeheadResults - The location typehead results.
 * @param {Function} props.setisDatePickerVisible - The date picker visibility state setter.
 * @returns {JSX.Element} - The HeroCover component.
 */
const HeroCover = () => {
  const nav = useNavigate();
  const bookingButtonRef = useRef(null);
  const checkInButtonRef = useRef(null);

  const [open, setOpen] = useState(false);

  const handleBookingClick = async () => {
    const icNo = localStorage.getItem('ICNO');
    const auth = localStorage.getItem('Authorization');
    const url = localStorage.getItem('api_URL');

    if (icNo == null || auth == null || url == null) {
      setOpen(true);
    }

    try {
      const data = await authentication(icNo, auth, url);
      if (data) {
        console.log(data);

        localStorage.setItem('token', data[0].Data[0].token);
        localStorage.setItem(
          'hotel',
          JSON.stringify({
            name: data[0].Data[0].property,
            address: data[0].Data[0].address,
          })
        );
        const button = bookingButtonRef.current;
        if (button) {
          button.style.animation = 'zoomIn 1s forwards';
          setTimeout(() => {
            nav('/booking');
          }, 1000); // Ganti halaman setelah 1 detik (durasi animasi)
        }
      } else {
        console.error('Authentication failed');
      }
    } catch (error) {
      console.error('Error during authentication', error);
    }
  };

  const handleCheckInClick = async () => {
    const icNo = localStorage.getItem('ICNO');
    const auth = localStorage.getItem('Authorization');
    const url = localStorage.getItem('api_URL');

    if (icNo == null || auth == null || url == null) {
      setOpen(true);
    }

    try {
      const data = await authentication(icNo, auth, url);
      if (data) {
        console.log(data);

        localStorage.setItem('token', data[0].Data[0].token);
        localStorage.setItem(
          'hotel',
          JSON.stringify({
            name: data[0].Data[0].property,
            address: data[0].Data[0].address,
          })
        );
        const button = checkInButtonRef.current;
        if (button) {
          button.style.animation = 'zoomIn 1s forwards';
          setTimeout(() => {
            nav('/checkIn');
          }, 1000); // Ganti halaman setelah 1 detik (durasi animasi)
        }
      } else {
        console.error('Authentication failed');
      }
    } catch (error) {
      console.error('Error during authentication', error);
    }
  };

  const returnBack = () => {
    setOpen(false);
    nav('/');
  };

  return (
    <div
      className="bg-brand min-h-screen text-slate-1000 flex"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '1000px',
        backgroundPositionX: '900px',
        animation: 'fadeInBackground 1s ease-in-out',
      }}
    >
      <style>
        {`
          @keyframes fadeInBackground {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
          @keyframes zoomIn {
            from {
              transform: scale(1);
            }
            to {
              transform: scale(2);
              opacity: 0;
            }
          }
        `}
      </style>
      <div className="hero-content__container flex flex-col container mx-auto px-2 md:px-0 mt-40">
        <></>
        <div className="hero-content__text py-4">
          <h3 className="text-4xl font-medium">
            <button
              ref={bookingButtonRef}
              className="px-16 py-9 rounded-full font-montserrat animate__animated animate__bounceInLeft animate__delay-1s"
              style={{
                backgroundColor: '#D9D9D9',
                color: '#004AAD',
                fontWeight: 'bold',
                fontSize: '72px',
              }}
              onClick={handleBookingClick}
            >
              BOOKING
            </button>
          </h3>
          <h3 className="text-4xl font-medium mt-6">
            <button
              ref={checkInButtonRef}
              onClick={handleCheckInClick}
              className="px-16 py-9 rounded-full font-montserrat animate__animated animate__bounceInLeft animate__delay-2s"
              style={{
                backgroundColor: '#D9D9D9',
                color: '#2BB4D4',
                fontWeight: 'bold',
                fontSize: '72px',
              }}
            >
              CHECK - IN
            </button>
          </h3>
          <h3 className="text-4xl font-medium mt-6">
            <button
              className="px-16 py-9 rounded-full font-montserrat animate__animated animate__bounceInLeft animate__delay-3s"
              style={{
                backgroundColor: '#D9D9D9',
                color: '#6A41BB',
                fontWeight: 'bold',
                fontSize: '72px',
              }}
            >
              SEARCH BOOKING ID
            </button>
          </h3>
        </div>
        <Modal
          isOpen={open}
          style={{
            content: {
              color: 'white',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            },
          }}
        >
          <div className="w-1/2 bg-red-500 h-1/2 flex flex-col justify-around items-center opacity-80 rounded-2xl px-2 py-4 mb-10">
            <h1 className="text-4xl font-semibold">Connection Failed To API</h1>
            <h1 className="text-4xl font-bold text-center">
              Please Check Your Connection Settings
            </h1>
          </div>
          <button
            className="bg-blue-600 w-1/2 rounded-2xl py-4 cursor-pointer hover:bg-white hover:text-blue-600 hover:border-blue-600 border-2"
            onClick={returnBack}
          >
            Back
          </button>
        </Modal>
      </div>
    </div>
  );
};

export default HeroCover;
