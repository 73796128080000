import axios from 'axios';

const getCountryList = async (apiURL, icNo, authorization) => {
  try {
    const response = await axios.get(`${apiURL}/api/web/country`, {
      headers: {
        'Content-Type': 'application/json',
        ICNO: icNo,
        Authorization: authorization,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      console.error('Unexpected response status:', response.status);
      return null;
    }
  } catch (e) {
    console.error('Error during request:', e.message);
    return null;
  }
};

export default getCountryList;
