import HeroCover from './components/hero-cover/HeroCover';
import { networkAdapter } from 'services/NetworkAdapter';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { MAX_GUESTS_INPUT_VALUE } from 'utils/constants';
import { formatDate } from 'utils/date-helpers';
import { useNavigate } from 'react-router-dom';
import _debounce from 'lodash/debounce';
import Modal from 'react-modal';

const Home = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const showRef = useRef(show);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [sucessPopUp, setSuccesPopUp] = useState(false);
  const [isDatePickerVisible, setisDatePickerVisible] = useState(false);
  const [locationInputValue, setLocationInputValue] = useState('pune');
  const [numGuestsInputValue, setNumGuestsInputValue] = useState('');

  const inputs = ['IC-NO', 'IC-KEY', 'API URL'];

  const [popularDestinationsData, setPopularDestinationsData] = useState({
    isLoading: true,
    data: [],
    errors: [],
  });
  const [hotelsResults, setHotelsResults] = useState({
    isLoading: true,
    data: [],
    errors: [],
  });

  useEffect(() => {
    showRef.current = show;
  }, [show]);

  useEffect(() => {
    const storedIcNO = localStorage.getItem('ICNO');
    const storedIcKey = localStorage.getItem('Authorization');
    const storedApiUrl = localStorage.getItem('api_URL');

    if (storedIcNO) setIcNO(storedIcNO);
    if (storedIcKey) setIcKey(storedIcKey);
    if (storedApiUrl) setApiUrl(storedApiUrl);

    const handleKeyDown = (event) => {
      if (event.shiftKey && event.code === 'KeyC') {
        if (showRef.current === false) {
          console.log('open');
          handleShow();
        } else if (showRef.current === true) {
          handleClose();
          console.log('close');
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const [availableCities, setAvailableCities] = useState([]);

  const [filteredTypeheadResults, setFilteredTypeheadResults] = useState([]);

  const debounceFn = useCallback(_debounce(queryResults, 1000), []);

  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection',
    },
  ]);

  const [icNo, setIcNO] = useState('');
  const [icKey, setIcKey] = useState('');
  const [apiUrl, setApiUrl] = useState('');

  const onChanges = [
    (e) => setIcNO(e.target.value),
    (e) => setIcKey(e.target.value),
    (e) => setApiUrl(e.target.value),
  ];

  const values = [icNo, icKey, apiUrl];

  const onDatePickerIconClick = () => {
    setisDatePickerVisible(!isDatePickerVisible);
  };

  const onLocationChangeInput = async (newValue) => {
    setLocationInputValue(newValue);
    debounceFn(newValue, availableCities);
  };

  function queryResults(query, availableCities) {
    const filteredResults = availableCities.filter((city) =>
      city.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredTypeheadResults(filteredResults);
  }

  const onNumGuestsInputChange = (numGuests) => {
    if (
      (numGuests < MAX_GUESTS_INPUT_VALUE && numGuests > 0) ||
      numGuests === ''
    ) {
      setNumGuestsInputValue(numGuests);
    }
  };

  const onDateChangeHandler = (ranges) => {
    setDateRange([ranges.selection]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    localStorage.setItem('ICNO', icNo);
    localStorage.setItem('Authorization', icKey);
    localStorage.setItem('api_URL', apiUrl);

    if (icNo && apiUrl && icKey) {
      setSuccesPopUp(true);

      setTimeout(() => {
        setShow(false);
        setSuccesPopUp(false);
      }, 3000);
    }
  };

  const onSearchButtonAction = () => {
    const numGuest = Number(numGuestsInputValue);
    const checkInDate = formatDate(dateRange[0].startDate) ?? '';
    const checkOutDate = formatDate(dateRange[0].endDate) ?? '';
    const city = locationInputValue;
    navigate('/hotels', {
      state: {
        numGuest,
        checkInDate,
        checkOutDate,
        city,
      },
    });
  };

  useEffect(() => {
    const formData = {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      address: '',
      country: 101,
      phoneNumber: '',
      city: '',
      email: '',
      note: '',
    };
    localStorage.removeItem('room_name');
    localStorage.setItem('user', JSON.stringify(formData));
    localStorage.removeItem('price');
    localStorage.removeItem('room_number');
    localStorage.setItem(
      'room',
      JSON.stringify({ roomTypeName: '', roomID: '' })
    );

    localStorage.setItem('desc', ' ');

    localStorage.setItem('durationStay', '');
    localStorage.setItem(
      'hourlyRate',
      JSON.stringify({ hourlysession_id: 0, hourlyrate_id: 0 })
    );

    localStorage.setItem(
      'time-reservation',
      JSON.stringify({
        time: String(new Date().getHours()).padStart(2, '0'),
        minute: String(new Date().getMinutes()).padStart(2, '0'),
      })
    );

    localStorage.setItem('book_id', '');
    localStorage.setItem(
      'date-reservation',
      new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      }).format(new Date())
    );

    localStorage.setItem('rate', JSON.stringify({}));
    localStorage.setItem('history', '');

    const getInitialData = async () => {
      const popularDestinationsResponse = await networkAdapter.get(
        '/api/popularDestinations'
      );
      const hotelsResultsResponse =
        await networkAdapter.get('/api/nearbyHotels');

      const availableCitiesResponse = await networkAdapter.get(
        '/api/availableCities'
      );
      if (availableCitiesResponse) {
        setAvailableCities(availableCitiesResponse.data.elements);
      }

      if (popularDestinationsResponse) {
        setPopularDestinationsData({
          isLoading: false,
          data: popularDestinationsResponse.data.elements,
          errors: popularDestinationsResponse.errors,
        });
      }
      if (hotelsResultsResponse) {
        setHotelsResults({
          isLoading: false,
          data: hotelsResultsResponse.data.elements,
          errors: hotelsResultsResponse.errors,
        });
      }
    };

    getInitialData();
  }, []);

  return (
    <>
      <HeroCover
        locationInputValue={locationInputValue}
        numGuestsInputValue={numGuestsInputValue}
        locationTypeheadResults={filteredTypeheadResults}
        isDatePickerVisible={isDatePickerVisible}
        setisDatePickerVisible={setisDatePickerVisible}
        onLocationChangeInput={onLocationChangeInput}
        onNumGuestsInputChange={onNumGuestsInputChange}
        dateRange={dateRange}
        onDateChangeHandler={onDateChangeHandler}
        onDatePickerIconClick={onDatePickerIconClick}
        onSearchButtonAction={onSearchButtonAction}
      />
      <Modal
        isOpen={show}
        onRequestClose={handleClose}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            display: 'flex',
          },
          content: {
            color: 'black',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          },
        }}
        contentLabel="Example Modal"
      >
        <div
          className={`p-5 w-3/5 text-center m-4 rounded-3xl bg-[#00bf63] text-white ${sucessPopUp ? 'block' : 'hidden'}`}
        >
          Server settings is Success
        </div>
        <form
          className="flex flex-col rounded-3xl px-[20px] py-[20px] w-3/5 bg-[#e3e3e3]"
          onSubmit={handleSubmit}
        >
          <h1 className="text-center py-4 rounded-full bg-[#bebae2] mb-4 font-bold text-xl">
            Server Settings
          </h1>
          {inputs.map((input, i) => {
            return (
              <div className="flex justify-between py-2" key={i}>
                <label
                  className="w-1/4 px-2 py-2 font-bold text-center rounded-full bg-white"
                  htmlFor={input}
                >
                  {input}
                </label>
                <input
                  type="text"
                  id={input}
                  className="w-3/5 rounded-full px-8"
                  value={values[i]}
                  onChange={onChanges[i]}
                />
              </div>
            );
          })}
          <div className="w-full justify-between flex mt-10">
            <button
              className="bg-[#ff3131] px-8 py-2 rounded-full text-white"
              onClick={handleClose}
            >
              Close
            </button>
            <button
              className="bg-[#00bf63] px-8 py-2 rounded-full text-white"
              type="submit"
            >
              Save
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default Home;
