import axios from 'axios';

const postPayment = async (apiURL, icNo, authorization, formData) => {
  console.log(formData, 'zzzz');
  const bodyFormData = new FormData();

  bodyFormData.append('first_name', formData.firstName);
  bodyFormData.append('last_name', formData.lastName);
  bodyFormData.append('birth_date', formData.birthDate);
  bodyFormData.append('address', formData.address);
  bodyFormData.append('country_id', formData.countryID);
  bodyFormData.append('city', formData.city);
  bodyFormData.append('email', formData.email);
  bodyFormData.append('arrival_date', formData.dateArrival);
  bodyFormData.append('departure_date', formData.depatureDate);
  bodyFormData.append('phone', formData.phone);
  bodyFormData.append('roomtype_id', formData.roomtypeID);
  bodyFormData.append('tariff_id', formData.tariffId);
  bodyFormData.append('rate_amount', formData.rateAmount);
  bodyFormData.append('additional_info', formData.note);
  bodyFormData.append('reg_token', formData.token);
  console.log(bodyFormData);
  try {
    const response = await axios.post(
      `${apiURL}/api/web/walkinmkios`,
      bodyFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          ICNO: icNo,
          Authorization: authorization,
        },
      }
    );
    return response;
    console.log('Reservation Response:', response.data);
  } catch (e) {
    console.error('Error during reservation request:', e.message);
    return null;
  }
};

export default postPayment;
