import axios from 'axios';

const searchBookingId = async (apiURL, icNo, icKey, bookingID) => {
  try {
    const response = await axios.get(
      `${apiURL}/api/web/reservation/${bookingID}`,

      {
        headers: {
          ICNO: icNo,
          Authorization: icKey,
        },
      }
    );

    if (response.status == '200') {
      return response;
    }
  } catch (error) {
    console.error(error);
  }
};

export default searchBookingId;
