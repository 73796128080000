import React, { useState, useRef, useEffect } from 'react';
import 'animate.css';
import backgroundImage from 'assests/logos/background.png'; // Pastikan path ini benar
import Modal from 'react-modal';
import ModalDanger from '../components/ModalDanger';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import getCountryList from 'routes/api/getCountryList';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import authentication from 'routes/api/Authentification';

const ConfirmOrderCheckIn = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const authorize = localStorage.getItem('Authorization');
  const url = localStorage.getItem('api_URL');
  const IcNo = localStorage.getItem('ICNO');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [city, setCity] = useState('');
  const [email, setEmail] = useState('');
  const [countryContainer, setCountryContainer] = useState([]);
  const [initialContainer, setInitialContainer] = useState([]);
  const [alert, setAlert] = useState(false);
  const [countryInitial, setCountryInitial] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [note, setNote] = useState('');

  const handleBack = () => {
    nav('/walkIn');
  };

  const handleBackClick = () => {
    nav('/');
  };

  const handleContinueClick = async () => {
    try {
      const data = await authentication(IcNo, authorize, url);
      if (data) {
        console.log(data);

        localStorage.setItem('token', data[0].Data[0].token);
        localStorage.setItem(
          'hotel',
          JSON.stringify({
            name: data[0].Data[0].property,
            address: data[0].Data[0].address,
          })
        );

        window.location.reload();
      } else {
        console.error('Authentication failed');
      }
    } catch (error) {
      console.error('Error during authentication', error);
    }

    setAlert(false);
  };

  const nav = useNavigate();
  const ScanIdCard = useRef(null);

  useEffect(() => {
    const authToken = localStorage.getItem('token');
    const apiURL = localStorage.getItem('api_URL');
    const IcNo = localStorage.getItem('ICNO');

    const data = JSON.parse(localStorage.getItem('user'));

    if (data) {
      setFirstName(data.firstName || '');
      setLastName(data.lastName || '');
      setDateOfBirth(data.dateOfBirth || '');
      setAddress(data.address || '');
      setCountry(data.country || '');
      setPhoneNumber(data.phoneNumber || '');
      setCity(data.city || '');
      setEmail(data.email || '');
      setSelectedCountry(data.country || '');
      setNote(data.note || '');
    }

    const searchCountry = async () => {
      try {
        const res = await getCountryList(apiURL, IcNo, authToken);

        console.log(res[0].Status);

        const countryOptions = res[0].Data.map((country) => ({
          value: country.CountryID,
          label: country.CountryName,
        }));

        if (res[0].Status == 200) {
          const initials = res[0].Data.map((initial) =>
            initial.CountryAlias.substring(0, 2)
          );
          setInitialContainer(initials);
          setCountryContainer(countryOptions);

          // Set default country based on user data
          const selectedCountryOption = countryOptions.find(
            (country) => country.value === selectedCountry
          );
          if (selectedCountryOption) {
            localStorage.setItem('region', selectedCountryOption.label);
            setCountryInitial(
              initials[countryOptions.indexOf(selectedCountryOption)] || ''
            );
          }
        } else {
          setAlert(true);
        }
      } catch (error) {
        console.error('Error fetching country list:', error);
        setAlert(true);
      }
    };

    searchCountry();
  }, [selectedCountry]);

  useEffect(() => {
    const storedInit = localStorage.getItem('init');
    if (storedInit) {
      setCountryInitial(storedInit);
    }
  }, []);

  useEffect(() => {
    if (initialContainer.length && country) {
      localStorage.setItem('init', initialContainer[country - 1]);
    }
  }, [country, initialContainer]);

  const handleInputChange = (setter) => (e) => {
    const { name, value } = e.target;
    setter(value);
    localStorage.setItem(
      'user',
      JSON.stringify({
        ...JSON.parse(localStorage.getItem('user')),
        [name]: value,
      })
    );
  };

  const handleInputPhoneNumber = (e) => {
    setPhoneNumber(e);
    localStorage.setItem(
      'user',
      JSON.stringify({
        ...JSON.parse(localStorage.getItem('user')),
        phoneNumber: e,
      })
    );
  };

  const handleCountryInput = (selectedOption) => {
    const newCountry = selectedOption.value;
    setCountry(newCountry);
    setSelectedCountry(newCountry);
    localStorage.setItem(
      'user',
      JSON.stringify({
        ...JSON.parse(localStorage.getItem('user')),
        country: newCountry,
      })
    );
    localStorage.setItem('region', selectedOption.label);
  };

  const handelScanIdCardClick = () => {
    const button = ScanIdCard.current;
    if (button) {
      button.style.animation = 'zoomIn 1s forwards';
      setTimeout(() => {
        nav('/ScanIdCard');
      }, 1000);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      firstName,
      lastName,
      dateOfBirth,
      address,
      country,
      phoneNumber,
      city,
      email,
      note,
    };

    if (Object.values(formData).every((field) => field !== '')) {
      console.log('success');
      nav('/checkInDetails');
      localStorage.setItem('user', JSON.stringify(formData));
    } else if (note == '') {
      nav('/checkInDetails');
      localStorage.setItem('user', JSON.stringify(formData));
    }
  };

  return (
    <div
      className="bg-brand min-h-screen text-slate-1000 flex bg-cover bg-no-repeat bg-center animate-[fadeInBackground_1s_ease-in-out]"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <style>
        {`
          @keyframes fadeInBackground {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
        `}
      </style>
      <div className="hero-content__container flex flex-col container mx-auto px-2 md:px-0 mt-1">
        <div className="hero-content__text py-4">
          <h1 className="text-[#2BB4D4] font-bold text-[2rem] ">CHECK IN</h1>
          <h3 className="text-3xl font-medium text-center border-2 border-[#2BB4D4] rounded-lg p-[1%] ">
            <button
              className="px-9 py-0.5 rounded-full font-montserrat mx-auto bg-[#2BB4D4] text-white font-extrabold text-[18px] "
              disabled
            >
              CONTACT DETAIL
            </button>
            <form
              onSubmit={handleSubmit}
              className="max-w-[10000px] my-0 mx-auto"
            >
              <div className="grid grid-cols-2 gap-4 mt-4">
                <div className="mb-4">
                  <label
                    htmlFor="first-name"
                    className="block text-left text-base font-medium text-gray-700"
                  >
                    First Name <span className="text-[red]">*</span>
                  </label>
                  <input
                    type="text"
                    id="first-name"
                    name="firstName"
                    className="mt-1 p-2 w-full border border-gray-300 rounded-md text-sm"
                    placeholder="Enter your first name"
                    value={firstName}
                    onChange={handleInputChange(setFirstName)}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="last-name"
                    className="block text-left text-base font-medium text-gray-700"
                  >
                    Last Name <span className="text-[red]">*</span>
                  </label>
                  <input
                    type="text"
                    id="last-name"
                    name="lastName"
                    className="mt-1 p-2 w-full border border-gray-300 rounded-md text-sm"
                    placeholder="Enter your last name"
                    value={lastName}
                    onChange={handleInputChange(setLastName)}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="date-of-birth"
                    className="block text-left text-base font-medium text-gray-700"
                  >
                    Date Of Birth <span className="text-[red]">*</span>
                  </label>
                  <input
                    type="date"
                    id="date-of-birth"
                    name="dateOfBirth"
                    className="mt-1 p-2 w-full border border-gray-300 rounded-md text-sm"
                    placeholder="Enter your date of birth"
                    value={dateOfBirth}
                    max="9999-12-31"
                    onChange={handleInputChange(setDateOfBirth)}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="address"
                    className="block text-left text-base font-medium text-gray-700"
                  >
                    Address <span className="text-[red]">*</span>
                  </label>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    className="mt-1 p-2 w-full border border-gray-300 rounded-md text-sm"
                    placeholder="Enter your address"
                    value={address}
                    onChange={handleInputChange(setAddress)}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="country"
                    className="block text-left text-base font-medium text-gray-700"
                  >
                    Country <span className="text-[red]">*</span>
                  </label>
                  <Select
                    type="text"
                    id="country"
                    name="country"
                    className="mt-1 w-full rounded-md text-sm text-left focus:outline-none"
                    placeholder="Select your country"
                    options={countryContainer}
                    onChange={handleCountryInput}
                    value={countryContainer.find(
                      (option) => option.value === country
                    )}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="phoneNumber"
                    className="block text-left text-base font-medium text-gray-700"
                  >
                    Phone Number (WhatsApp No){' '}
                    <span className="text-[red]">*</span>
                  </label>
                  <PhoneInput
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    className="text-left text-sm h-[2.5rem]"
                    placeholder="Enter your phone Number"
                    defaultCountry={countryInitial}
                    value={phoneNumber}
                    onChange={handleInputPhoneNumber}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="city"
                    className="block text-left text-base font-medium text-gray-700"
                  >
                    City <span className="text-[red]">*</span>
                  </label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    className="mt-1 p-2 w-full border border-gray-300 rounded-md text-sm"
                    placeholder="Enter your city"
                    value={city}
                    onChange={handleInputChange(setCity)}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block text-left text-base font-medium text-gray-700"
                  >
                    Email <span className="text-[red]">*</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="mt-1 p-2 w-full border border-gray-300 rounded-md text-sm"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleInputChange(setEmail)}
                    required
                  />
                </div>
                <div className="col-span-2 ">
                  <label
                    htmlFor="note"
                    className="block text-left text-base font-medium text-gray-700"
                  >
                    Note
                  </label>
                  <textarea
                    type="note"
                    id="note"
                    name="note"
                    className="mt-1 p-2 w-full border border-gray-300 rounded-md text-sm"
                    placeholder=" Please Write Note Here"
                    value={note}
                    onChange={handleInputChange(setNote)}
                  ></textarea>
                </div>
                <div className="col-span-2 grid grid-cols-3 gap-3">
                  <div className="mb-0">
                    <button
                      type="button"
                      className="w-full px-6 py-1 text-white bg-red-600 rounded-3xl hover:bg-red-700"
                      style={{
                        fontWeight: 'bold',
                        fontSize: '18px',
                      }}
                      onClick={handleBack}
                    >
                      BACK
                    </button>
                  </div>
                  <div className="mb-0">
                    <button
                      ref={ScanIdCard}
                      type="button"
                      className="w-full px-6 py-1 text-white bg-[#2BB4D4] rounded-3xl hover:bg-[#2BB4D4] font-bold text-[18px]"
                      onClick={handelScanIdCardClick}
                    >
                      SCAN YOUR ID CARD
                    </button>
                  </div>
                  <div className="mb-0">
                    <button
                      type="submit"
                      className="w-full px-6 py-1 text-white bg-green-600 rounded-3xl hover:bg-green-700 font-bold text-[18px]"
                    >
                      NEXT
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </h3>
        </div>
      </div>
      <Modal
        isOpen={alert}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          content: {
            margin: 'auto',
            border: 'none',
            padding: '0',
            borderRadius: '10px',
            overflowY: 'hidden',
          },
        }}
      >
        <ModalDanger
          hanldeBack={handleBackClick}
          hanldeContinue={handleContinueClick}
        />
      </Modal>
    </div>
  );
};

export default ConfirmOrderCheckIn;
