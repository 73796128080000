import axios from 'axios';

const getTime = async (IcNo, auth, apiURL) => {
  try {
    const response = await axios.get(`${apiURL}/api/web/hourminute`, {
      headers: {
        ICNO: IcNo,
        Authorization: auth,
      },
    });

    if (response.status === 200) {
      return response.data; // Mengembalikan data respons langsung
    } else {
      console.error('Unexpected response status:', response.status);
    }
  } catch (error) {
    console.error('Error during request:', error);
  }
};

export default getTime;
